@if (imageName) {
    <div class="rule-progress" [style.background-image]="'url(/assets/images/' + imageName + ')'"></div>
}
<div [formGroup]="form">
    <div class="rule-conditions-form" [formArrayName]="formGroupName">
        <ng-content select="[header]"></ng-content>
        @for (control of conditionsFormArray.controls; let index = $index; track control) {
            <div class="condition-field">
                <condition-form
                    [formGroup]="control"
                    [condition]="ruleConditions[index]"
                    (deleteClicked)="onDeleteCondition(index)"
                    [variants]="variants"
                    [ruleProperties]="ruleProperties">
                </condition-form>
            </div>
        }
        <nuc-button-secondary class="add-condition" text="Add condition" icon="nucicon_add" iconPosition="end"
                              (click)="onAddConditionClicked()"></nuc-button-secondary>
    </div>
</div>
