import {inject, Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {Observable} from 'rxjs';
import {ARPagedResponseDataModel} from '@relayter/core';
import {WorkflowAutomationModel, WorkflowAutomationRuleBodyModel, WorkflowAutomationRuleModel} from '../../models/api/workflow-automation.model';
import {JobModel} from '../../models/api/job.model';
import {tap} from 'rxjs/operators';
import {MonitoredJobsService} from './monitored-updates/monitored-jobs.service';
import {ApiService} from './api.service';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {ApiPostRequest} from '../requests/api-post.request';
import {ApiDetailsRequest} from '../requests/api-details.request';
import {IPatchBodyModel} from '../../models/interfaces/patch-body-model.interface';
import {ApiPutRequest} from '../requests/api-put.request';
import {ApiDeleteOneRequest} from '../requests/api-delete-one.request';
import {ApiPostJobRequest} from '../requests/api-post-job.request';
import {NewCursor} from '../new-api-cursor';
import {TableSortOptions} from '../table-sort-options';
import {EInsertLocation} from '@relayter/rubber-duck';
import {StaticContentRulesetModel} from '../../modules/static-content-rulesets/models/api/static-content-ruleset.model';
import {ApiPatchRequest} from '../requests/api-patch.request';

export enum EWorkflowAutomationJobType {
    WORKFLOW_AUTOMATION_RULE_COPY_JOB = 'WORKFLOW_AUTOMATION_RULE_COPY_JOB',
    WORKFLOW_AUTOMATION_COPY_JOB = 'WORKFLOW_AUTOMATION_COPY_JOB'
}

@Injectable({
    providedIn: 'root'
})
export class WorkflowAutomationsService extends ApiService<WorkflowAutomationModel> {
    private monitoredJobsService = inject(MonitoredJobsService);

    constructor() {
        super([ApiConstants.API_GROUP_WORKFLOW_AUTOMATIONS], WorkflowAutomationModel);
    }

    public getWorkflowAutomations(limit: number,
                                  offset: number,
                                  cursor?: NewCursor,
                                  sortColumns?: TableSortOptions): Observable<ARPagedResponseDataModel<WorkflowAutomationModel>> {
        const request = new ApiPagedRequest(this.groups, this.model, limit, offset, sortColumns, cursor);
        return this.apiRequestService.find(request);
    }

    public getWorkflowAutomationRules(workflowAutomationId: string,
                                      limit: number,
                                      offset: number,
                                      cursor?: NewCursor,
                                      sortColumns?: TableSortOptions,
                                      search?: string): Observable<ARPagedResponseDataModel<WorkflowAutomationRuleModel>> {
        const request = new ApiPagedRequest([...this.groups, workflowAutomationId, ApiConstants.API_GROUP_WORKFLOW_AUTOMATION_RULES],
            WorkflowAutomationRuleModel, limit, offset, sortColumns, cursor, search);
        return this.apiRequestService.find(request);
    }

    public createAutomationRule(workflowAutomationId: string, body: WorkflowAutomationRuleBodyModel): Observable<WorkflowAutomationRuleModel> {
        const request = new ApiPostRequest([...this.groups, workflowAutomationId, ApiConstants.API_GROUP_WORKFLOW_AUTOMATION_RULES],
            WorkflowAutomationRuleModel, body);
        return this.apiRequestService.create(request);
    }

    public getAutomationRule(workflowAutomationId: string, ruleId: string): Observable<WorkflowAutomationRuleModel> {
        const request = new ApiDetailsRequest([...this.groups, workflowAutomationId, ApiConstants.API_GROUP_WORKFLOW_AUTOMATION_RULES],
            WorkflowAutomationRuleModel, ruleId);
        return this.apiRequestService.findOne(request);
    }

    public updateAutomationRule(workflowAutomationId: string, ruleId: string,
                                body: IPatchBodyModel): Observable<WorkflowAutomationRuleModel> {
        const request = new ApiPutRequest([...this.groups, workflowAutomationId, ApiConstants.API_GROUP_WORKFLOW_AUTOMATION_RULES],
            WorkflowAutomationRuleModel, ruleId, body);
        return this.apiRequestService.put(request);
    }

    public deleteWorkflowAutomationRule(workflowAutomationId: string, ruleId: string): Observable<boolean> {
        const request = new ApiDeleteOneRequest([...this.groups, workflowAutomationId, ApiConstants.API_GROUP_WORKFLOW_AUTOMATION_RULES],
            ruleId);
        return this.apiRequestService.deleteOne(request);
    }

    public postJob(jobType: EWorkflowAutomationJobType, jobData: Record<string, any>): Observable<JobModel> {
        const request = new ApiPostJobRequest(this.groups, JobModel, jobType, jobData);

        return this.apiRequestService.postJob(request)
            .pipe(
                tap((job: JobModel) => this.monitoredJobsService.addItemToMonitor(job))
            );
    }

    public orderRulesetItems(workflowAutomationId, targetId, sourceId, insertLocation?: EInsertLocation): Observable<StaticContentRulesetModel> {
        const body: Record<string, any> = {
            targetId,
            sourceId
        };
        if (insertLocation) {
            body.insertLocation = insertLocation;
        }
        const request = new ApiPatchRequest(this.groups, WorkflowAutomationModel, workflowAutomationId, body, ApiConstants.API_METHOD_ORDER);
        return this.apiRequestService.patch(request);
    }
}
