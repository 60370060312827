import {Injectable, OnDestroy} from '@angular/core';
import {NotificationsService} from './notifications.service';
import {ENotificationStatus, NotificationModel} from '../../models/api/notification.model';
import {BehaviorSubject, Subject} from 'rxjs';
import {AppConstants} from '../../app.constants';
import {takeUntil} from 'rxjs/operators';

@Injectable()
export class NotificationsDataService implements OnDestroy {

    private notificationsSubject = new BehaviorSubject<NotificationModel[]>(null);
    public notifications$ = this.notificationsSubject.asObservable();

    private archivedNotificationsSubject = new BehaviorSubject<NotificationModel[]>(null);
    public archivedNotifications$ = this.archivedNotificationsSubject.asObservable();

    private subjects = [this.notificationsSubject, this.archivedNotificationsSubject];
    private onDestroySubject = new Subject<void>();

    constructor(private notificationsService: NotificationsService) {
        this.getNotifications();
        this.getArchivedNotifications();

        this.notificationsService.notificationUpdates$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(() => this.getNotifications());
    }

    public ngOnDestroy(): void {
        this.subjects.forEach((subject) => subject.complete());
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    // TODO: handle paging
    public getNotifications(): void {
        this.notificationsService.getNotifications(AppConstants.PAGE_SIZE_MAX,
            0,
            'createdAt',
            'desc',
            [ENotificationStatus.READ, ENotificationStatus.UNREAD])
            .subscribe((result) => this.notificationsSubject.next(result.items));
    }

    // TODO: handle paging
    private getArchivedNotifications(): void {
        this.notificationsService.getNotifications(AppConstants.PAGE_SIZE_MAX,
            0,
            'createdAt',
            'desc',
            [ENotificationStatus.ARCHIVED])
            .subscribe((result) => this.archivedNotificationsSubject.next(result.items));
    }

    public markAsRead(notificationId: string): void {
        this.notificationsService.setNotificationStatus(notificationId, ENotificationStatus.READ)
            .subscribe(() => this.getNotifications());
    }

    public markAsArchived(notificationId: string): void {
        this.notificationsService.setNotificationStatus(notificationId, ENotificationStatus.ARCHIVED)
            .subscribe(() => {
                this.getNotifications();
                this.getArchivedNotifications();
            });
    }
}
