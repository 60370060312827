import {inject, Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {finalize} from 'rxjs/operators';
import {AssetModel} from '../../../../../../models/api/asset.model';
import {AssetApiService} from '../../../../../../api/services/asset.api.service';
import {RelayterTableDataProvider} from '../../../../../../classes/relayter-table-data-provider.service';
import {TableSortOptions} from '../../../../../../api/table-sort-options';
import {Toaster} from '../../../../../../classes/toaster.class';

@Injectable()
export class AssetDataProvider extends RelayterTableDataProvider<AssetModel> {
    private assetApiService = inject(AssetApiService);

    public productIds: string[];

    constructor(public sortOptions: TableSortOptions) {
        super(sortOptions);
    }

    public retrieveData(): void {
        this.loading = true;

        this.assetApiService.getAssets(this.pageSize, 0, this.searchValue, this.sortOptions, this.filterValues,
            this.apiCursor.getCursor(this.pageIndex), this.productIds)
            .pipe(
                finalize(() => this.loading = false),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe({
                next: (result) => {
                    this.data = result.items;
                    this.disableNextPage = !result.hasNext;
                    if (this.data.length > 0) {
                        this.setCursor();
                    }
                },
                error: Toaster.handleApiError
            });
    }

    public setCursor(): void {
        super.setCursor();
    }
}
