import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AssetModel} from '../../../models/api/asset.model';
import {ESelectionMode, IActionClickEvent, ISortOptionEvent, ITableAction, ITableColumn, ITableItem} from '@relayter/rubber-duck';
import {ARPagedResponseDataModel} from '@relayter/core';
import {Toaster} from '../../../classes/toaster.class';
import {AppConstants} from '../../../app.constants';
import {takeUntil, tap} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {EDataFieldCollectionName} from '../../../app.enums';
import {DataFieldsComponentUtil} from '../../../classes/data-fields-component.util';
import {DataFieldsApiService} from '../../../api/services/data-fields.api.service';
import {RLTableComponent} from '../../../components/rl-base-component/rl-table.component';
import {UserSettingsStorageService} from '../../../api/services/user-settings-storage.service';
import {DataFieldModel} from '../../../models/api/data-field.model';
import {PaginatorService} from '../../../components/paginator/paginator.service';
import {ProductModel} from '../../../models/api/product.model';
import {DataFilterModel} from '../../../models/ui/data-filter.model';
import {AdvancedFiltersDataService} from '../../../api/services/advanced-filters.data-service';
import {AssetApiService} from '../../../api/services/asset.api.service';
import {AssetsTableConfig} from '../../../classes/table-config/assets.table-config';
import {DataFilterUtil, EDataFilterContext} from '../../../components/data-filter/data-filter.util';

@Component({
    selector: 'campaign-item-assets-form',
    templateUrl: './campaign-item-assets-form.component.html',
    styleUrls: ['./campaign-item-assets-form.component.scss'],
    providers: [PaginatorService, AdvancedFiltersDataService]
})
export class CampaignItemAssetsFormComponent extends RLTableComponent implements OnInit, OnDestroy {
    public readonly tableId = 'campaign-item-form-assets-table';

    @Input() public readonly = false;

    @Input() public form: UntypedFormGroup;
    @Input() public selectedAssets: AssetModel[];
    @Input() public selectedProducts: ProductModel[] = [];

    public allAssets: AssetModel[] = [];

    public allColumns: ITableColumn[];
    public allSelectedColumns: ITableColumn[];

    public selectionMode = ESelectionMode.MULTI;
    public actionTypes: ITableAction[] = [AppConstants.TABLE_ACTION_TYPES.DOWNLOAD];

    public pageSize: number;
    public pageIndex = 1;
    public disableNextPage = true;
    public showLinkedAssetsOnly = false;
    public disableSort = false;

    private onDestroySubject = new Subject<void>();

    public assetsSubscription: Subscription;
    public searching: boolean = false;

    public EDataFilterContextAsset = EDataFilterContext.ASSET;
    public dataFilters: DataFilterModel[] = [];

    private dataFieldComponentUtil: DataFieldsComponentUtil;

    constructor(private assetService: AssetApiService,
                private dataFieldsService: DataFieldsApiService,
                private paginatorService: PaginatorService,
                private advancedFiltersDataService: AdvancedFiltersDataService,
                userSettingsStorageService: UserSettingsStorageService) {
        super(userSettingsStorageService);

        this.dataFieldComponentUtil = inject(DataFieldsComponentUtil);
    }

    public ngOnInit(): void {
        this.initForm();

        this.dataFilters = DataFilterUtil.setupDefaultDataFilters(EDataFilterContext.ASSET);

        this.selectedProducts = this.form.contains('products') ? this.form.get('products').value : this.selectedProducts;

        this.getTableData();

        this.advancedFiltersDataService.getFilterValues()
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe(filterValues => {
                if (this.filterValues !== filterValues) {
                    this.filterValues = filterValues;

                    this.paginatorService.setPageIndex(this.tableId, 1);
                }
            });

        this.paginatorService.getPagination(this.tableId).pipe(takeUntil(this.onDestroySubject))
            .subscribe(pagination => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) {
                    this.newApiCursor.reset(this.pageIndex, this.tableSortOptions);
                }

                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.getAssets();
            });
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public actionClicked(actionEvent: IActionClickEvent): void {
        if (actionEvent.action.title === AppConstants.TABLE_ACTION_TYPES.DOWNLOAD.title) {
            window.open(actionEvent.item['files'].source.url);
        }
    }

    private initForm(): void {
        this.form.contains('assets') ?
            this.selectedAssets = this.form.get('assets').value || [] :
            this.form.addControl('assets', new UntypedFormControl(this.selectedAssets));
    }

    private getTableData(): void {
        this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.ASSET)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (dataFields) => {
                    this.initializeTable(dataFields);
                    this.paginatorService.setPageIndex(this.tableId, 1);
                },
                error: Toaster.handleApiError
            });
    }

    private initializeTable(dataFields: DataFieldModel[]): void {
        this.allColumns = [
            ...AssetsTableConfig.ASSETS_TABLE_DEFAULT_COLUMNS,
            ...this.dataFieldComponentUtil.getDataFieldsColumnSelection(dataFields)
        ];

        this.tableSortOptions.columns = [this.allColumns.find((column) => column.key === 'updatedAt')];
        this.tableSortOptions.sortOrder = 'desc';

        this.allSelectedColumns = [...this.allColumns, AssetsTableConfig.ASSETS_SELECTED_ICON_COLUMN]
            .map(column => ({...column, sortProperty: undefined}));
    }

    private getAssets(): void {
        if (this.assetsSubscription) this.assetsSubscription.unsubscribe();

        const cursor = this.newApiCursor.getCursor(this.pageIndex);

        if (this.showLinkedAssetsOnly && this.selectedProducts?.length === 0) { // no need to do an api call when products are empty
            this.allAssets = [];
            this.disableNextPage = true;
            return;
        }

        const productIds = this.showLinkedAssetsOnly ? this.selectedProducts.map((product) => product._id) : null;

        this.assetsSubscription =
            this.assetService.getAssets(this.pageSize, 0, this.searchValue, this.tableSortOptions, this.filterValues, cursor, productIds)
                .pipe(
                    tap(() => {
                        this.searching = !!this.searchValue;
                    }),
                    takeUntil(this.onDestroySubject)
                )
                .subscribe({
                    next: (res: ARPagedResponseDataModel<AssetModel>) => {
                        this.allAssets = res.items;
                        this.disableNextPage = !res.hasNext;

                        if (res.items.length > 0) {
                            const item = this.allAssets[this.allAssets.length - 1];
                            this.newApiCursor.setCursor(this.pageIndex, item);
                        }
                    },
                    error: Toaster.handleApiError
                });
    }

    public onSelectionChanged(selectedAssets: ITableItem[]): void {
        this.selectedAssets = selectedAssets as AssetModel[];
        this.form.get('assets').setValue(this.selectedAssets);
    }

    public onSearchBarValueUpdated(searchValue: string): void {
        if (this.searchValue !== searchValue) {
            this.searchValue = searchValue;
            this.searching = !!this.searchValue;
            this.disableSort = !!this.searchValue;

            this.paginatorService.setPageIndex(this.tableId, 1);
        }
    }

    public onSortOptionChanged(sortEvent: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(sortEvent);
        this.newApiCursor.reset(1, this.tableSortOptions);
        this.paginatorService.setPageIndex(this.tableId, 1);
    }

    public onCheckboxValueChange(value: boolean): void {
        this.showLinkedAssetsOnly = value;
        this.paginatorService.setPageIndex(this.tableId, 1);
    }
}
