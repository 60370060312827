import {ApiConstants} from '../api.constant';
import {Injectable} from '@angular/core';
import {ProductAssetExportSetupModel} from '../../models/api/product-asset-export-setup.model';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {JobModel} from '../../models/api/job.model';
import {ApiPostJobRequest} from '../requests/api-post-job.request';
import {tap} from 'rxjs/operators';
import {MonitoredJobsService} from './monitored-updates/monitored-jobs.service';
import {ApiGetRequest} from '../requests/api-get.request';
import {ItemUsedModel} from '../../models/api/item-used.model';

export enum EProductAssetExportSetupJobType {
    EDIT_PRODUCT_ASSET_EXPORT_SETUP_JOB = 'EDIT_PRODUCT_ASSET_EXPORT_SETUP_JOB',
    DELETE_PRODUCT_ASSET_EXPORT_SETUP_JOB = 'DELETE_PRODUCT_ASSET_EXPORT_SETUP_JOB'
}

@Injectable({providedIn: 'root'})
export class ProductAssetExportSetupsService extends ApiService<ProductAssetExportSetupModel> {

    constructor(private monitoredJobsService: MonitoredJobsService) {
        super([ApiConstants.API_GROUP_PRODUCT_ASSET_EXPORT_SETUPS], ProductAssetExportSetupModel);
    }

    public postJob(jobType: EProductAssetExportSetupJobType, jobData: Record<string, any>): Observable<JobModel> {
        const request = new ApiPostJobRequest(this.groups, JobModel, jobType, jobData);
        return this.apiRequestService.postJob(request)
            .pipe(
                tap((job: JobModel) => this.monitoredJobsService.addItemToMonitor(job))
            );
    }

    public getUsages(itemId: string): Observable<ItemUsedModel> {
        const request = new ApiGetRequest([...this.groups, itemId, ApiConstants.API_METHOD_USAGE], ItemUsedModel);
        return this.apiRequestService.get(request);
    }
}
