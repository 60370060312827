@if (permissions.GET_CAMPAIGN_ITEMS | userIsAllowedTo) {
    <nuc-table [emptyStateTitle]="assetDataProvider.searching ? 'No item matches your search criteria.' : 'This section is empty.'"
               [tableDataProvider]="assetDataProvider"
               [columns]="columns"
               [actions]="assetActions"
               [selectionMode]="assetDataProvider.selectionMode"
               [tableId]="tableId"
               [prefixStorageKey]="storageKey"
               [tableSortOptions]="assetDataProvider.sortOptions"
               (tableSortOptionsChange)="resetPageIndex()"
               (actionClicked)="handleTableRowAction($event)">
        <ng-container button-bar-left>
            <nuc-input class="search"
                       [(data)]="assetDataProvider.searchValue"
                       prefix="nucicon_search"
                       (debounceValueChanged)="resetPageIndex()"
                       [placeholder]="'Search assets'">
            </nuc-input>
            <data-filter [dataFilterContext]="EDataFilterContextAsset"
                         [filters]="dataFilters"
                         [disabledFilters]="disabledFilters"
                         [initialValues]="assetFilterConfigFilterValues" />
        </ng-container>

        <ng-container button-bar-right>
            @if (assetDataProvider.hasValue) {
                <div class="popout-button">
                    <nuc-button-secondary
                        text="{{assetDataProvider.selected.length}} selected {{assetDataProvider.selected.length === 1 ? 'item' : 'items'}}"
                        icon="nucicon_dots_vertical"
                        iconPosition="end"
                        [matMenuTriggerFor]="menu">
                    </nuc-button-secondary>
                    <mat-menu #menu="matMenu" class="menu-items-container">
                        @for (action of multiCompatibleActions; track action._id) {
                            <button mat-menu-item
                                    (click)="handleMultiSelectionAction(action)"
                                    [disableRipple]="true">{{ action.title }}
                            </button>
                        }
                    </mat-menu>
                </div>

                <div class="vertical-line"></div>
            }

            <paginator [viewId]="tableId"
                       [disableNextPage]="assetDataProvider.disableNextPage"
                       [loading]="assetDataProvider.loading">
            </paginator>
        </ng-container>
    </nuc-table>
} @else {
    <no-permission-state-component></no-permission-state-component>
}
