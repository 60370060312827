<nuc-tab-bar [tabs]="tabBarItems" [(activeTab)]="selectedTab"></nuc-tab-bar>
<hr class="tab-bar-hr" />

<ng-container [ngSwitch]="selectedTab.index">
    <form [formGroup]="formGroup">
        <div class="rule-description-container" *ngSwitchCase="TAB_RULE_DESCRIPTION">
            <nuc-form-field label="Name">
                <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Template">
                <nuc-dropdown formControlName="template"
                              [searchable]="true"
                              (requestData)="searchTemplates($event)"
                              [items]="templates"
                              [total]="totalTemplates"
                              placeholder="Select a template">
                </nuc-dropdown>
            </nuc-form-field>


            <nuc-form-field label="Create publication items without matches">
                <nuc-switch
                    formControlName="alwaysCreatePublicationItem"
                    text="Always add publication item with the selected template even if there are no briefing items that match the conditions.">
                </nuc-switch>
            </nuc-form-field>

            <nuc-form-field label="Publication item creation setting">
                <nuc-advanced-radiobutton
                    formControlName="createSinglePublicationItem"
                    [dataItems]="createSinglePublicationItemOptions">
                </nuc-advanced-radiobutton>
            </nuc-form-field>
        </div>

        <ng-container *ngIf="ruleProperties.length; else loading" >
            <rule-conditions-form-component *ngSwitchCase="TAB_CONDITIONS"
                                            imageName="workflow_automation_rule_conditions.svg"
                                            [form]="formGroup"
                                            [ruleConditions]="rule?.conditions || []"
                                            [variants]="variants"
                                            [ruleProperties]="ruleProperties">
            </rule-conditions-form-component>
        </ng-container>
        <ng-template #loading>
            <rl-loading-indicator></rl-loading-indicator>
        </ng-template>
    </form>
</ng-container>
