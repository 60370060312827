<div class="upload-box">
    <div class="upload-header">
        <div class="upload-title">
            <p class="title black body-strong">{{fileUploadTitle}}</p>
            @if (subtitle) { <p class="title">{{subtitle}}</p>}
            <p class="required" [class.highlight]="!newFileUploaded && required && saveClicked">{{required ? 'Required' : 'Optional'}}</p>
        </div>
        <i class="nucicon_check upload-check" [class.active]="active"></i>
    </div>
    <div class="file-input">
        <rl-reactive-file-input
            inactive-title="Drag your {{dragTitle}} here"
            [multiple]="multiple"
            (filesChanged)="onFileChanged($event)">
        </rl-reactive-file-input>
    </div>
</div>
