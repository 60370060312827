<div class="item" [formGroup]="receiverForm()">
    <div>Receiver #{{ receiverIndex() + 1 }}</div>

    <nuc-button-bar>
        <nuc-button-secondary
            icon="nucicon_trash_fill"
            class="delete-button"
            (click)="deleteClicked.emit()">
        </nuc-button-secondary>
    </nuc-button-bar>

    <nuc-form-field label="Receiver type">
        <nuc-dropdown formControlName="type"
                      [nullOption]="false"
                      [total]="receiverTypeOptions.length"
                      [items]="receiverTypeOptions"
                      (dataChange)="typeChanged($event)"
                      placeholder="Select a receiver type">
        </nuc-dropdown>
    </nuc-form-field>

    @switch (receiverForm().controls.type.value?.getValue()) {
        @case (EReceiverType.ROLE) {
            <nuc-form-field label="Value">
                <nuc-dropdown-multiselect
                        formControlName="roles"
                        [items]="roles()"
                        placeholder="Select role(s)">
                </nuc-dropdown-multiselect>
            </nuc-form-field>
        }
        @case (EReceiverType.MAIL) {
            <nuc-form-field label="Value">
                <nuc-suggestive-input
                    formControlName="emails"
                    [options]="[]"
                    placeholder="Add e-mail addresses">
                </nuc-suggestive-input>
            </nuc-form-field>
        }
        @case (EReceiverType.PROPERTY) {
            <nuc-form-field label="Value">
                <property-control
                    formControlName="property"
                    [ruleProperties]="propertyOptions()"
                    [variantRequired]="propertyControlOptions().variantRequired"
                    [ignoreVariant]="propertyControlOptions().ignoreVariant"
                    [variants]="variants()"/>
            </nuc-form-field>
        }
    }
</div>
