import {Component, input, output} from '@angular/core';
import {EReceiverType} from '../workflow-configuration-step-form/workflow-configuration-step-form.component';
import {ComponentsModule} from '../../components/components.module';
import {
    NUCButtonBarModule,
    NUCButtonsModule,
    NUCComponentsModule,
    NUCInputsModule,
    NUCTooltipModule
} from '@relayter/rubber-duck';
import {AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {RoleModel} from '../../models/api/role.model';
import {RulePropertyModel} from '../../models/api/rule-property.model';
import {RLValidatorRegExConstants} from '../../classes/validators/rl-validator-regex.constant';
import {PropertyValueModel} from '../../models/ui/property-value.model';
import {PropertyControlValidator} from '../../classes/validators/property-control.validator';
import {IDropdownItem} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {VariantModel} from '../../models/api/variant.model';
import {PropertyControlOptions} from '../../components/property-control/property-control.options';

@Component({
    selector: 'workflow-configuration-receiver-form-component',
    templateUrl: 'workflow-configuration-receiver-form.component.html',
    styleUrls: ['workflow-configuration-receiver-form.component.scss'],
    imports: [
        ComponentsModule,
        ReactiveFormsModule,
        NUCButtonBarModule,
        NUCButtonsModule,
        NUCComponentsModule,
        NUCInputsModule,
        NUCTooltipModule
    ],
    standalone: true
})

export class WorkflowConfigurationReceiverFormComponent {
    protected readonly EReceiverType = EReceiverType;
    public deleteClicked = output<void>();

    public receiverForm = input<FormGroup>();
    public receiverIndex = input<number>();
    public roles = input<RoleModel[]>();
    public propertyOptions = input<RulePropertyModel[]>();
    public variants = input<VariantModel[]>();
    public propertyControlOptions = input<PropertyControlOptions>();

    public receiverTypeOptions: DropdownItem<EReceiverType>[] = [
        new DropdownItem('Role', EReceiverType.ROLE),
        new DropdownItem('E-mail', EReceiverType.MAIL),
        new DropdownItem('Publication item property', EReceiverType.PROPERTY)
    ];

    public typeChanged(type: IDropdownItem<EReceiverType>): void {
        if (!type) return;

        switch(type.getValue()) {
            case EReceiverType.ROLE:
                this.receiverForm().removeControl('emails', {emitEvent: false});
                this.receiverForm().removeControl('property', {emitEvent: false});
                this.receiverForm().addControl('roles', new FormControl<DropdownItem<string>[]>([], [Validators.required, Validators.minLength(1)]));
                break;
            case EReceiverType.MAIL:
                this.receiverForm().removeControl('roles', {emitEvent: false});
                this.receiverForm().removeControl('property', {emitEvent: false});
                this.receiverForm().addControl('emails', new FormControl<DropdownItem<string>[]>([],
                    [Validators.required, Validators.minLength(1), (control: AbstractControl) => {
                        const emails = control.value;
                        if (Array.isArray(emails) && emails.length &&
                            !emails.every(email => !!email.getValue().match(RLValidatorRegExConstants.EMAIL))) {
                            return {email: 'Invalid email address'};
                        }

                        return null;
                    }]));
                break;
            case EReceiverType.PROPERTY:
                this.receiverForm().removeControl('roles', {emitEvent: false});
                this.receiverForm().removeControl('emails', {emitEvent: false});
                this.receiverForm().addControl('property', new FormControl<PropertyValueModel>(null,
                    [Validators.required, PropertyControlValidator()]))
                break;
        }
    }
}
