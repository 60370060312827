import {Injectable} from '@angular/core';
import {ApiConstants} from '../api.constant';
import {ApiService} from './api.service';
import {
    AnimatedContentRulesetModel,
    AnimatedContentRulesetRuleModel,
    AnimatedContentRulesetRulePostModel
} from '../../models/api/animated-content-ruleset.model';
import {ApiPagedRequest} from '../requests/api-paged.request';
import {Observable} from 'rxjs';
import {ApiPostRequest} from '../requests/api-post.request';
import {TableSortOptions} from '../table-sort-options';
import {ARPagedResponseDataModel} from '@relayter/core';
import {ApiGetRequest} from '../requests/api-get.request';
import {ItemUsedModel} from '../../models/api/item-used.model';
import {ApiPatchRequest} from '../requests/api-patch.request';
import {ApiDetailsRequest} from '../requests/api-details.request';
import {ApiDeleteRequest} from '../requests/api-delete.request';

@Injectable({
    providedIn: 'root'
})
export class AnimatedContentRulesetApiService extends ApiService<AnimatedContentRulesetModel> {
    constructor() {
        super([ApiConstants.API_METHOD_ANIMATED_CONTENT_RULESETS], AnimatedContentRulesetModel);
    }

    public getAnimatedContentRulesetsByAfterEffectsProjectFileId(
        afterEffectsProjectFileId?: string,
        limit?: number,
        offset?: number
    ): Observable<AnimatedContentRulesetModel[]> {
        const request = new ApiPagedRequest(
            [...this.groups], AnimatedContentRulesetModel, limit, offset);

        request.additionalQueryParams.addParam('afterEffectsProjectFileId', afterEffectsProjectFileId);

        return this.apiRequestService.findAll(request);
    }

    public getAnimatedContentRulesetRules(
        rulesetId?: string,
        limit?: number,
        offset?: number,
        tableSortOptions: TableSortOptions = new TableSortOptions()
    ): Observable<ARPagedResponseDataModel<AnimatedContentRulesetRuleModel>> {
        const request = new ApiPagedRequest(
            [
                ...this.groups,
                rulesetId,
                ApiConstants.API_METHOD_ANIMATED_CONTENT_RULESETS_RULES
            ], AnimatedContentRulesetRuleModel, limit, offset, tableSortOptions);

        return this.apiRequestService.find(request);
    }

    public getAnimatedContentRulesetRule(
        rulesetId?: string,
        ruleId?: string
    ): Observable<AnimatedContentRulesetRuleModel> {
        const request = new ApiDetailsRequest(
            [
                ...this.groups,
                rulesetId,
                ApiConstants.API_METHOD_ANIMATED_CONTENT_RULESETS_RULES,
            ], AnimatedContentRulesetRuleModel, ruleId);

        return this.apiRequestService.findOne(request);
    }

    public createAnimatedContentRulesetRule(
        rulesetId: string,
        rule: AnimatedContentRulesetRulePostModel
    ): Observable<AnimatedContentRulesetRuleModel> {
        const request = new ApiPostRequest(
            [
                ...this.groups,
                rulesetId,
                ApiConstants.API_METHOD_ANIMATED_CONTENT_RULESETS_RULES
            ], AnimatedContentRulesetRuleModel, rule);

        return this.apiRequestService.create(request);
    }

    public updateAnimatedContentRulesetRule(
        rulesetId: string,
        ruleId: string,
        rule: AnimatedContentRulesetRulePostModel
    ) {
        const request = new ApiPatchRequest(
            [
                ...this.groups,
                rulesetId,
                ApiConstants.API_METHOD_ANIMATED_CONTENT_RULESETS_RULES
            ], AnimatedContentRulesetRuleModel, ruleId, rule);

        return this.apiRequestService.patch(request);
    }

    public deleteAnimatedContentRulesetRule(
        rulesetId: string,
        ruleId: string,
    ) {
        const request = new ApiDeleteRequest(
            [
                ...this.groups,
                rulesetId,
                ApiConstants.API_METHOD_ANIMATED_CONTENT_RULESETS_RULES,
                ruleId
            ]);

        return this.apiRequestService.delete(request);
    }

    public getAnimatedContentRulesetUsage(
        rulesetId: string
    ): Observable<ItemUsedModel> {
        const request = new ApiGetRequest(
            [
                ...this.groups,
                rulesetId,
                ApiConstants.API_METHOD_USAGE
            ], ItemUsedModel);

        return this.apiRequestService.get(request);
    }
}
