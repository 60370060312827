import {Component, inject, OnInit} from '@angular/core';
import {ESelectionMode, ITableColumn, NucPopOutContentService, RDModule} from '@relayter/rubber-duck';
import {CustomWorkflowBaseComponent} from '../custom-workflow-base.component';
import {CustomWorkflowActionModel} from '../../../../../../models/api/custom-workflow-action.model';
import {UserSettingsStorageService} from '../../../../../../api/services/user-settings-storage.service';
import {ComponentsModule} from '../../../../../../components/components.module';
import {PipesModule} from '../../../../../../pipes/pipes.module';
import {EDataFieldCollectionName} from '../../../../../../app.enums';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {AssetDataProvider} from './asset.data-provider';
import {DataFieldsComponentUtil} from '../../../../../../classes/data-fields-component.util';
import {DataFieldsApiService} from '../../../../../../api/services/data-fields.api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Toaster} from '../../../../../../classes/toaster.class';
import {PaginatorService} from '../../../../../../components/paginator/paginator.service';
import {TableSortOptions} from '../../../../../../api/table-sort-options';
import {AdvancedFiltersDataService} from '../../../../../../api/services/advanced-filters.data-service';
import {EWorkflowComponentOptionName} from '../../../../../../models/api/custom-workflow-option.model';
import {AssetsTableConfig} from '../../../../../../classes/table-config/assets.table-config';
import {DataFilterComponent} from '../../../../../../components/data-filter/data-filter.component';
import {DataFilterModel} from '../../../../../../models/ui/data-filter.model';
import {DataFilterUtil, EDataFilterContext} from '../../../../../../components/data-filter/data-filter.util';

@Component({
    selector: 'custom-workflow-assets-component',
    templateUrl: './custom-workflow-assets.component.html',
    styleUrls: ['./custom-workflow-assets.component.scss'],
    standalone: true,
    imports: [
        RDModule,
        ComponentsModule,
        PipesModule,
        MatMenu,
        MatMenuItem,
        MatMenuTrigger,
        DataFilterComponent
    ],
    providers: [
        PaginatorService,
        AdvancedFiltersDataService
    ]
})
export class CustomWorkflowAssetsComponent extends CustomWorkflowBaseComponent implements OnInit {
    public selectionMode = ESelectionMode.EXPAND;

    private paginatorService = inject(PaginatorService);
    private advancedFiltersDataService = inject(AdvancedFiltersDataService);

    private userSettingsStorageService = inject(UserSettingsStorageService);
    private dataFieldComponentUtil = inject(DataFieldsComponentUtil);
    private dataFieldsService = inject(DataFieldsApiService);

    public tableId: string;
    public readonly storageKey: string;
    public columns: ITableColumn[];

    public tableSortOptions = new TableSortOptions();
    public assetDataProvider = new AssetDataProvider(this.tableSortOptions);
    public assetFilterConfigFilterValues: Record<string, any> = {};
    public disabledFilters: string[];
    public dataFilters: DataFilterModel[];

    protected readonly EDataFilterContextAsset = EDataFilterContext.ASSET;

    constructor(popOutService: NucPopOutContentService) {
        super(popOutService);
        this.storageKey = this.userSettingsStorageService.getPrefixStorageKey();
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.ASSET)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (dataFields) => {
                    const createAtIndex = AssetsTableConfig.ASSETS_TABLE_DEFAULT_COLUMNS.findIndex(item => item.key === 'createdAt');
                    this.columns = [...AssetsTableConfig.ASSETS_TABLE_DEFAULT_COLUMNS];
                    this.columns.splice(createAtIndex, 0, ...this.dataFieldComponentUtil.getDataFieldsColumnSelection(dataFields));
                },
                error: Toaster.handleApiError
            });

        this.advancedFiltersDataService.getFilterValues()
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
                (filterValues: Record<string, any>) => {
                    // merge with filters from config
                    this.assetDataProvider.filterValues = {...this.assetFilterConfigFilterValues, ...filterValues};
                    this.resetPageIndex();
                }
            );

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((pagination) => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.assetDataProvider.pageSize) {
                    this.assetDataProvider.resetCursorArray(pagination.pageIndex);
                }

                this.assetDataProvider.pageIndex = pagination.pageIndex;
                this.assetDataProvider.pageSize = pagination.pageSize;

                this.getAssets();
            });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public handleTableRowAction(): void {
        // ToDo: Handle asset actions
    }

    private getAssets(): void {
        if (this.userIsAllowedToPipe.transform(this.permissions.GET_ASSETS)) {
            this.assetDataProvider.retrieveData();
        }
    }

    protected refreshData(): void {
        this.getAssets();
    }

    protected setupData(): void {
        // Ensure each use of this component has its own config for properties
        this.tableId = `custom-workflow-assets-${this.component._id}`;

        const optionValue = this.component.options?.find((option) =>
            option.name === EWorkflowComponentOptionName.ASSETS_FILTER)?.value || [];
        this.assetFilterConfigFilterValues = optionValue.reduce((accum, config) => {accum[config.property] = config.value; return accum;}, {});
        this.disabledFilters = Object.keys(this.assetFilterConfigFilterValues);
        this.assetDataProvider.initSelection(this.multiCompatibleActions.length > 0 ? ESelectionMode.MULTI : ESelectionMode.EXPAND);

        // Default data filters for assets
        this.dataFilters = DataFilterUtil.setupDefaultDataFilters(EDataFilterContext.ASSET);
    }

    protected resetPageIndex(): void {
        this.paginatorService.setPageIndex(this.tableId, 1); // reset pageIndex
    }

    public handleMultiSelectionAction(action: CustomWorkflowActionModel): void {
        this.handleAction(action, this.assetDataProvider.selected);
        this.assetDataProvider.clear();
    }
}
