import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CustomWorkflowActionModel, EDownloadActionName} from '../../../../../../../models/api/custom-workflow-action.model';
import {PublicationItemModel} from '../../../../../../../models/api/publication-item.model';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {TabBarItemModel} from '../../../../../../../models/ui/tab-bar-item.model';
import {CustomWorkflowFilterModel} from '../../../../../../../models/api/custom-workflow-filter.model';
import {CustomWorkflowFilterOptionModel} from '../../../../../../../models/api/custom-workflow-filter-option.model';
import {PublicationModel} from '../../../../../../../models/api/publication.model';
import {CustomWorkflowStepModel} from '../../../../../../../models/api/custom-workflow-step.model';
import {Subject} from 'rxjs';
import {IPackageType} from './package-type/files-download-package-type.component';
import {VariantModel} from '../../../../../../../models/api/variant.model';

export interface IFilesDownloadModalData {
    actions: CustomWorkflowActionModel[];
    activeFilters: Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>;
    publication: PublicationModel;
    step: CustomWorkflowStepModel;
    variant: VariantModel;
}

@Component({
    selector: 'rl-custom-workflow-files-download-component',
    templateUrl: './custom-workflow-files-download.component.html',
    styleUrls: ['./custom-workflow-files-download.component.scss']
})
export class CustomWorkflowFilesDownloadComponent implements OnInit, OnDestroy {
    public readonly tableId = 'custom-workflow-files-download-table';
    // tab bar related
    public TAB_PACKAGE_TYPE = 0;
    public TAB_ITEMS = 1;
    public packageTab = new TabBarItemModel('Package type', this.TAB_PACKAGE_TYPE);
    public itemsTab = new TabBarItemModel('Publication items', this.TAB_ITEMS);
    public tabBarItems: TabBarItemModel[] = [this.packageTab, this.itemsTab];

    private _selectedTab = this.tabBarItems[this.TAB_PACKAGE_TYPE];
    public actions: CustomWorkflowActionModel[];

    public get selectedTab(): TabBarItemModel {
        return this._selectedTab;
    }

    public set selectedTab(tab: TabBarItemModel) {
        if (tab !== this._selectedTab) {
            const index = this.tabBarItems.find((t) => t.title === tab.title).index;
            this._selectedTab = tab;
            this._selectedTab.index = index;
            this.setConfirmButton();
            this.updateButtonStatus();
        }
    }

    public selectedPackageType: IPackageType;
    private _selectedPublicationItems: PublicationItemModel[] = [];
    public set selectedPublicationItems(items: PublicationItemModel[]) {
        this._selectedPublicationItems = items;
        this.updateButtonStatus();
    }

    public get selectedPublicationItems() {
        return this._selectedPublicationItems;
    }

    private confirmButton: ButtonConfig;
    private onConfirmClicked: () => void = this.nextAction;

    private onDestroySubject = new Subject<void>();

    constructor(private fullModalService: FullModalService,
                @Inject(NUC_FULL_MODAL_DATA) public modalData: IFilesDownloadModalData) {
        this.actions = modalData.actions;
    }

    public ngOnInit(): void {
        this.initializeModalButtons();
    }

    public ngOnDestroy(): void {
        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    private initializeModalButtons(): void {
        this.confirmButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Next', null, null, false);
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');

        const confirm = new FullModalActionModel(this.confirmButton);
        const cancel = new FullModalActionModel(cancelButton);

        cancel.observable.subscribe(() => this.fullModalService.close());
        confirm.observable.subscribe(() => this.onConfirmClicked());
        this.fullModalService.setModalActions([cancel, confirm]);
    }

    private nextAction(): void {
        this.selectedTab = this.tabBarItems.find((tab) => tab.index === this.selectedTab.index + 1);
    }

    private downloadAction(): void {
        const selectedIds = this.selectedPublicationItems.map(item => item._id);
        const jobData: any = {
            publicationItems: selectedIds,
            packageType: this.selectedPackageType.packageType,
        };
        if (this.selectedPackageType.action === EDownloadActionName.DOWNLOAD_CUSTOM_EXPORT_PACKAGE) {
            jobData.format = this.selectedPackageType.format;
            jobData.size = this.selectedPackageType.size;
        }
        this.fullModalService.close(jobData);
    }

    private setConfirmButton(): void {
        switch (this._selectedTab.index) {
            case this.TAB_PACKAGE_TYPE:
                this.confirmButton.text = 'Next';
                this.onConfirmClicked = this.nextAction;
                return;
            case this.TAB_ITEMS:
                this.confirmButton.text = 'Download';
                this.onConfirmClicked = this.downloadAction;
                break;
        }
    }

    /**
     * When the button text is `Next`, it's always enabled
     * When the button text is `Download`, we check if we have selectedPackageType and selectedItems
     */
    public updateButtonStatus(): void {
        this.confirmButton.disabled = this.selectedTab.index === this.TAB_ITEMS &&
            (this.selectedPublicationItems.length === 0 || !this.selectedPackageType);
    }
}
