import {Component, computed, DestroyRef, inject, OnInit, signal} from '@angular/core';
import {distinctUntilChanged} from 'rxjs/operators';
import {FormArray, FormControl, FormControlStatus, FormGroup, Validators} from '@angular/forms';
import {BUTTON_TYPE, ButtonConfig, FullModalActionModel, FullModalService, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {DropdownItem} from '../../models/ui/dropdown-item.model';
import {CustomWorkflowStepModel} from '../../models/api/custom-workflow-step.model';
import {WorkflowConfigurationsService} from '../../api/services/workflow-configurations.service';
import {ModelUtil} from '../../classes/model.util';
import {
    CUSTOM_WORKFLOW_COMPONENT_TYPES_CONFIGS,
    CustomWorkflowComponentModel,
    EColumnConfigModel,
    EComponentTypeContext,
    ECustomWorkflowComponentType,
    IComponentOptionConfig,
    IComponentTypeConfig
} from '../../models/api/custom-workflow-component.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {WorkflowConfigurationModel} from '../../models/api/workflow-configuration.model';
import {IDropdownItem, IDropdownRequestDataEvent} from '@relayter/rubber-duck/lib/interfaces/idropdown-item';
import {Toaster} from '../../classes/toaster.class';
import {StringUtil} from '../../classes/string-util';
import {CustomWorkflowOptionModel, EWorkflowComponentOptionName} from '../../models/api/custom-workflow-option.model';
import {EDataFieldCollectionName, EFormStatus} from '../../app.enums';
import {DataFieldsApiService} from '../../api/services/data-fields.api.service';
import {DataFieldModel} from '../../models/api/data-field.model';
import {forkJoin, Subscription} from 'rxjs';
import {PropertyService} from '../../api/services/property.service';
import {RuleConditionModel} from '../../models/api/rule-condition.model';
import {AdvancedFiltersDataService} from '../../api/services/advanced-filters.data-service';
import {DataFilterUtil} from '../../components/data-filter/data-filter.util';
import {DataFilterModel} from '../../models/ui/data-filter.model';

export interface IWorkflowConfigurationComponentFormData {
    workflowConfiguration: WorkflowConfigurationModel;
    component?: CustomWorkflowComponentModel;
    workflowConfigurationStep?: CustomWorkflowStepModel;
}

interface IColumnConfigForm {
    displayName: FormControl<string>;
    property: FormControl<IDropdownItem<string>>;
}

interface IOptionsColumnConfigForm {
    [key: string]: FormArray<FormGroup<IColumnConfigForm>>;
}

interface IOptionsAssetFilterForm {
    [key: string]: FormGroup<Record<string, any>>;
}

interface IComponentForm {
    name: FormControl<string>;
    componentType: FormControl<IDropdownItem<IComponentTypeConfig>>;
    options?: FormGroup<IOptionsColumnConfigForm> | FormGroup<IOptionsAssetFilterForm>;
}

@Component({
    selector: 'workflow-configuration-component-form-component',
    templateUrl: 'workflow-configuration-component-form.component.html',
    styleUrls: ['workflow-configuration-component-form.component.scss'],
    providers: [AdvancedFiltersDataService]
})
export class WorkflowConfigurationComponentFormComponent implements OnInit {
    private destroyRef = inject(DestroyRef);
    private fullModalService = inject(FullModalService);
    private workflowConfigurationService = inject(WorkflowConfigurationsService);
    private dataFieldsService = inject(DataFieldsApiService);
    private modalData: IWorkflowConfigurationComponentFormData =
        inject<IWorkflowConfigurationComponentFormData>(NUC_FULL_MODAL_DATA, {optional: true});
    private propertyService = inject(PropertyService);

    public permissions: DropdownItem<string>[];
    public formGroup: FormGroup<IComponentForm>;
    private saveButton: ButtonConfig;
    private workflowConfiguration: WorkflowConfigurationModel;
    private workflowConfigurationStep: CustomWorkflowStepModel;
    public component: CustomWorkflowComponentModel;
    public allComponentTypes: IDropdownItem<IComponentTypeConfig>[];
    public componentTypes: IDropdownItem<IComponentTypeConfig>[];

    protected readonly EWorkflowComponentOptionName = EWorkflowComponentOptionName;
    private campaignItemDataFields: DataFieldModel[];
    private columnSubscriptions: Subscription[] = [];
    public assetConditions: RuleConditionModel[];

    // we set up the properties in the frontend instead of doing an api call
    // to be able to use display properties, such as templateType.name (backend is posType.name) and pageType (projected field)
    public allColumnConfigProperties: DropdownItem<string>[];
    public columnConfigProperties: DropdownItem<string>[];

    protected assetDataFields: DataFieldModel[];

    private readonly templateProperties = [
        new DropdownItem('Template name', 'template.name'),
        new DropdownItem('Template type', 'template.templateType.name')
    ];

    private readonly publicationItemProperties = [
        new DropdownItem('Page type', 'pageType'),
        new DropdownItem('Items assigned', 'numberOfAssignedItems')
    ];
    private selectedComponentOptionConfig = signal<IComponentOptionConfig[]>([]);
    public hasColumnConfig = computed<boolean>(() =>
        !!this.selectedComponentOptionConfig()?.find(optionConfig => optionConfig.name === EWorkflowComponentOptionName.COLUMN_CONFIG));
    public hasAssetsFilterConfig = computed<boolean>(() =>
        !!this.selectedComponentOptionConfig()?.find(optionConfig => optionConfig.name === EWorkflowComponentOptionName.ASSETS_FILTER));
    public filters: DataFilterModel[] = [];

    public ngOnInit(): void {
        this.setupData();
    }

    public setupData(): void {
        this.workflowConfiguration = this.modalData.workflowConfiguration;
        this.component = this.modalData.component || new CustomWorkflowComponentModel();
        if (this.component.componentType === ECustomWorkflowComponentType.ASSETS) {
            this.assetConditions = this.component.options?.find(option =>
                option.name === EWorkflowComponentOptionName.ASSETS_FILTER)?.value as RuleConditionModel[];
        }

        this.workflowConfigurationStep = this.modalData.workflowConfigurationStep;
        this.allComponentTypes = CUSTOM_WORKFLOW_COMPONENT_TYPES_CONFIGS
            .filter((config) => {
                return config.context === (this.workflowConfigurationStep ? EComponentTypeContext.STEP : EComponentTypeContext.WORKFLOW)
            })
            .map(config => new DropdownItem<IComponentTypeConfig>(config.type, config));
        this.componentTypes = this.allComponentTypes;

        this.selectedComponentOptionConfig.set(this.allComponentTypes.find((componentType) =>
            this.component?.componentType === componentType.getTitle())?.getValue()?.optionsConfig || []);

        forkJoin({
            campaignItemDataFields: this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.CAMPAIGN_ITEM),
            assetDataFields: this.dataFieldsService.getAllDataFields(EDataFieldCollectionName.ASSET)
        }).pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ({campaignItemDataFields, assetDataFields}) => {
                    this.campaignItemDataFields = campaignItemDataFields;
                    this.assetDataFields = assetDataFields;

                    this.initForm();
                    this.initModalButtons();
                },
                error: Toaster.handleApiError
            })

    }

    public onRequestComponentTypes(request: IDropdownRequestDataEvent): void {
        if (request.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(request.search), 'i');
            this.componentTypes = this.allComponentTypes.filter((componentType) =>
                componentType.getTitle().match(regex)?.length > 0);
        } else {
            this.componentTypes = this.allComponentTypes;
        }
    }

    private initModalButtons(): void {
        const cancelButton = new ButtonConfig(BUTTON_TYPE.SECONDARY, 'Cancel');
        this.saveButton = new ButtonConfig(BUTTON_TYPE.PRIMARY, 'Save', null, false);
        this.saveButton.disabled = this.formGroup.status !== EFormStatus.VALID;

        const cancelAction = new FullModalActionModel(cancelButton);
        const saveAction = new FullModalActionModel(this.saveButton);

        cancelAction.observable.subscribe(() => this.fullModalService.close(false, true));
        saveAction.observable.subscribe(() => {
            this.saveWorkflowConfigurationComponent();
        });
        this.fullModalService.setModalActions([cancelAction, saveAction]);

        if (this.workflowConfigurationStep) {
            this.fullModalService.setDescription(`Enter the information to create a new component for step: ${this.workflowConfigurationStep.name}.`);
        }
    }

    private initForm(): void {
        const selectedComponentType = this.componentTypes.find((componentType) =>
            this.component?.componentType === componentType.getTitle());

        this.formGroup = new FormGroup<IComponentForm>({
            name: new FormControl(this.component.name, Validators.required),
            componentType: new FormControl({value: selectedComponentType, disabled: !!selectedComponentType},
                [Validators.required]
            )
        });

        if (this.selectedComponentOptionConfig()) {
            this.addOptionsFormGroup(this.component?.options || []);
        }

        this.listenToFormChanges();
        this.listenToComponentTypeControl();
    }

    private listenToFormChanges(): void {
        this.formGroup.statusChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((status: FormControlStatus) => this.saveButton.disabled = status !== EFormStatus.VALID);
    }

    private listenToComponentTypeControl(): void {
        this.formGroup.controls.componentType.valueChanges.pipe(
            distinctUntilChanged(),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe((value: IDropdownItem<IComponentTypeConfig>) => {
            // reset options form control
            this.formGroup.removeControl('options');
            this.selectedComponentOptionConfig.set(value.getValue().optionsConfig || []);

            if (this.selectedComponentOptionConfig()) this.addOptionsFormGroup();
        });
    }

    private addOptionsFormGroup(options: Record<string, any> = []): void {
        for (const optionConfig of this.selectedComponentOptionConfig()) {
            const value = options?.find((option) => optionConfig.name === option.name)?.value;

            switch (optionConfig.name) {
                case EWorkflowComponentOptionName.COLUMN_CONFIG: {
                    if (optionConfig.model === EColumnConfigModel.PUBLICATION_ITEM) {
                        this.allColumnConfigProperties = this.campaignItemDataFields.map((dataField) =>
                            new DropdownItem(dataField.name, 'campaignItems.0.dataFields.' + dataField.fieldName))
                            .concat(this.templateProperties, this.publicationItemProperties);
                    } else {
                        this.allColumnConfigProperties = this.campaignItemDataFields.map((dataField) =>
                            new DropdownItem(dataField.name, 'campaignItem.dataFields.' + dataField.fieldName))
                            .concat(this.publicationItemProperties
                                .filter((property) => property.getValue() !== 'numberOfAssignedItems')
                                .concat(this.templateProperties).map((property) => {
                                    return new DropdownItem(property.getTitle(), 'publicationItem.' + property.getValue());
                                })
                            );
                    }
                    this.columnConfigProperties = this.allColumnConfigProperties;

                    const formArray = new FormArray([]);
                    value?.forEach((item) => formArray.push(this.createColumnFormGroup(item)));
                    // this is to help with the save button status, otherwise we can use two forms
                    this.formGroup.addControl('options', new FormGroup({
                        [optionConfig.name as string]: formArray
                    }));
                    break;
                }
                case EWorkflowComponentOptionName.ASSETS_FILTER: {
                    this.filters = DataFilterUtil.createDataFilters(undefined, this.assetDataFields);
                    const formValue = value?.reduce((accum, entry) => {accum[entry.property] = entry.value; return accum}, {});
                    this.formGroup.addControl('options', new FormGroup({
                        [optionConfig.name as string]: DataFilterUtil.createFilterFormGroup(this.filters, formValue)
                    }));
                    break;
                }
                default:
                    Toaster.error(`Workflow component option: ${optionConfig.name} is not supported yet.`);
            }
        }
    }

    private saveWorkflowConfigurationComponent(): void {
        const component = ModelUtil.createApiBody({
            name: this.formGroup.value.name,
            componentType: this.formGroup.getRawValue().componentType.getTitle(),
            options: this.createOptionsBody(),
        }, this.component._id);

        this.saveButton.loading = true;
        const observable = this.component._id
            ? this.workflowConfigurationService.patchWorkflowConfigurationComponent(
                this.workflowConfiguration._id, this.component._id, component, this.workflowConfigurationStep?._id)
            : this.workflowConfigurationService.createWorkflowConfigurationComponent(
                this.workflowConfiguration._id, component, this.workflowConfigurationStep?._id)

        observable
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                error: (error) => {
                    this.saveButton.loading = false;
                    Toaster.handleApiError(error);
                },
                next: (workflowConfiguration) => {
                    this.fullModalService.close(workflowConfiguration);
                    Toaster.success(`Component ${this.component._id ? 'updated' : 'created'} successfully`);
                }
            });
    }

    public createColumnFormGroup(value: Record<string, any>): FormGroup<IColumnConfigForm> {
        const selectedProperty = this.columnConfigProperties.find((property) => property.getValue() === value.property);

        const propertyControl = new FormControl(selectedProperty, Validators.required);
        const displayNameControl = new FormControl(value.displayName, Validators.required);

        // listen to property control
        const subscription = propertyControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((property) => {
                if (!displayNameControl.value) displayNameControl.patchValue(property.getTitle());
            });

        this.columnSubscriptions.push(subscription);

        return new FormGroup<IColumnConfigForm>({
            displayName: displayNameControl,
            property: propertyControl
        });
    }

    public addColumn(formArray: FormArray<FormGroup<IColumnConfigForm>>): void {
        formArray.push(this.createColumnFormGroup({}));
    }

    public removeColumn(formArray: FormArray<FormGroup<IColumnConfigForm>>, index: number): void {
        formArray.removeAt(index);
        const subscription = this.columnSubscriptions.splice(index, 1);
        subscription[0].unsubscribe();
    }

    private createOptionsBody(): CustomWorkflowOptionModel[] {
        const formattedOptions: CustomWorkflowOptionModel[] = [];

        for (const [optionName, value] of Object.entries(this.formGroup.controls.options?.value || {})) {
            if (!value || (Array.isArray(value) && value.length === 0)) continue;

            switch (optionName) {
                case EWorkflowComponentOptionName.COLUMN_CONFIG: {
                    // we trust the form group
                    const formattedValue = (value).map((v: Record<string, any>) => {
                        return {
                            displayName: v.displayName,
                            property: v.property.getValue()
                        }
                    });
                    formattedOptions.push({
                        name: optionName,
                        value: formattedValue
                    });
                    break;
                }
                case EWorkflowComponentOptionName.ASSETS_FILTER: {
                    const formattedValue = DataFilterUtil.getFilterValues(value);
                    formattedOptions.push({
                        name: optionName,
                        value: Object.keys(formattedValue).map(key => ({property: key, value: formattedValue[key]}))
                    });
                    break;
                }
                default:
                    Toaster.error(`Workflow component option: ${optionName} is not supported yet.`);
                    break;
            }
        }
        return formattedOptions;
    }

    public onSearchColumnProperty(request: IDropdownRequestDataEvent): void {
        if (request.search) {
            const regex = new RegExp(StringUtil.escapeRegExp(request.search), 'i');
            this.columnConfigProperties = this.allColumnConfigProperties.filter((columnConfigProperty) =>
                columnConfigProperty.getTitle().match(regex)?.length > 0);
        } else {
            this.columnConfigProperties = this.allColumnConfigProperties;
        }
    }
}
