@if (subscription?.closed) {
    <nuc-tab-bar [tabs]="tabbarItems"
                 [(activeTab)]="selectedTab">
    </nuc-tab-bar>
    <hr class="full-width-tab-hr"/>

    <form [formGroup]="form">
        @switch (selectedTab.index) {
            @case (TAB_INFORMATION) {
                <package-rule-information-form-component [form]="form"
                                                         [packageRule]="packageRule">
                </package-rule-information-form-component>
            }
            @case (TAB_CONDITIONS) {
                <rule-conditions-form-component imageName="package_rule_illustration_conditions.svg"
                                                [form]="form"
                                                [variants]="variants"
                                                [ruleConditions]="packageRule.conditions"
                                                [ruleProperties]="ruleProperties">
                    <ng-container header>
                        <p class="section-header">Conditions</p>
                        <p class="explanation">Set up one or more conditions.</p>
                    </ng-container>
                </rule-conditions-form-component>
            }
            @case (TAB_MATERIAL_DATA) {
                <package-rule-material-data-form-component [form]="form"
                                                           [dataFieldValues]="packageRule.dataFields"
                                                           [dataFields]="modalData.packageSetup.materialDataFields">
                </package-rule-material-data-form-component>
            }
        }
    </form>
} @else {
    <rl-loading-indicator size="large"></rl-loading-indicator>
}
