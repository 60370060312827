import {Component, OnDestroy, OnInit, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {PublicationModel} from '../../../../../models/api/publication.model';
import {CampaignModel} from '../../../../../models/api/campaign.model';
import {TIME_OUT_TYPES, Toaster} from '../../../../../classes/toaster.class';
import {AppConstants} from '../../../../../app.constants';
import {WorkflowConfigurationModel} from '../../../../../models/api/workflow-configuration.model';
import {TabBarItemModel} from '../../../../../models/ui/tab-bar-item.model';
import {CustomWorkflowStepModel} from '../../../../../models/api/custom-workflow-step.model';
import {PublicationsService} from '../../../../../api/services/publications.service';
import {UserIsAllowedToPipe} from '../../../../../pipes/user-is-allowed-to.pipe';
import {CustomWorkflowFilterModel} from '../../../../../models/api/custom-workflow-filter.model';
import {CustomWorkflowFilterOptionModel} from '../../../../../models/api/custom-workflow-filter-option.model';
import {CustomWorkflowActionModel} from '../../../../../models/api/custom-workflow-action.model';
import {
    CustomWorkflowComponentModel,
    ECustomWorkflowComponentType
} from '../../../../../models/api/custom-workflow-component.model';
import {
    BUTTON_TYPE,
    EToastType,
    FullModalConfig,
    FullModalService,
    NucDialogConfigModel,
    NucDialogService,
    ToastDataModel
} from '@relayter/rubber-duck';
import {RLBaseComponent} from '../../../../../components/rl-base-component/rl-base.component';
import {CustomWorkflowService} from './custom-workflow.service';
import {catchError, debounceTime, finalize, map, startWith, switchMap, take, takeUntil} from 'rxjs/operators';
import {combineLatest, forkJoin, Observable, of, Subject} from 'rxjs';
import {CustomWorkflowOverviewComponent} from './custom-workflow-overview/custom-workflow-overview.component';
import {
    CustomWorkflowStickyLogComponent,
    ICustomWorkflowStickyLogModalData
} from './custom-workflow-sticky-list/custom-workflow-sticky-log.component';
import {RLCountsModel} from '../../../../../api/response/rl-counts.model';
import {MatrixUrlWorkflowParams} from '../../../../../models/ui/matrix-url-workflow-params.model';
import {SocketService} from '../../../../../api/services/socket/socket.service';
import {PublicationItemSelectionService} from './custom-workflow-item-selection/publication-item-selection.service';
import {PublicationItemModel} from '../../../../../models/api/publication-item.model';
import {ETransitionStatus, TransitionItemModel} from '../../../../../models/api/transition-item.model';
import {Deserialize} from 'cerialize';
import {MonitoredTransitionsService} from '../../../../../api/services/monitored-updates/monitored-transitions.service';
import {IWorkflowModalData} from '../../../../../models/interfaces/workflow-modal-data.interface';
import {VariantModel} from '../../../../../models/api/variant.model';
import {UserSettingsStorageService} from '../../../../../api/services/user-settings-storage.service';
import {VariantService} from '../../../../../api/services/variant.service';
import {WorkflowConfigurationsService} from '../../../../../api/services/workflow-configurations.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'rl-custom-workflow-component',
    templateUrl: 'custom-workflow.component.html',
    styleUrls: ['custom-workflow.component.scss'],
    providers: [CustomWorkflowService, PublicationItemSelectionService],
    animations: [
        trigger('headerAnimation', [
            state('visible', style({height: '*', opacity: 1, overflow: 'hidden'})),
            state('hidden', style({height: '0px', opacity: 0, overflow: 'hidden'})),
            transition('visible <=> hidden', [animate('0.2s 25ms ease-in-out')])
        ]),
        trigger('componentAnimation', [
            state('visible', style({height: 'var(--workflow-working-area-height)'})),
            state('hidden', style({height: 'var(--workflow-working-area-height-without-header)'})),
            transition('visible <=> hidden', [animate('0.2s 25ms ease-in-out')])
        ])
    ]
})
export class CustomWorkflowComponent extends RLBaseComponent implements OnInit, OnDestroy {

    private readonly WORKFLOW_VARIANT_SETTING = 'workflow_variant';
    public readonly ECustomWorkflowComponentType = ECustomWorkflowComponentType;

    public campaign: CampaignModel;
    public publication: PublicationModel;
    public workflow: WorkflowConfigurationModel;
    public activeStep: CustomWorkflowStepModel;
    public workflowActions: CustomWorkflowActionModel[];
    public tabs: TabBarItemModel[] = [];
    public activeTab: TabBarItemModel;
    public component: CustomWorkflowComponentModel;
    public stickyLogComponent: CustomWorkflowComponentModel;
    public overviewComponent: CustomWorkflowComponentModel;
    public isStepDisabled: boolean = false;
    public variants: VariantModel[] = [];
    public headerVisible: boolean = true;

    public redirectPublicationItem: PublicationItemModel;

    public counts: RLCountsModel;
    public activeFilters: Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]> =
        new Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>();

    // TODO: Remove this property and handle refreshing on the components by subscribing socket updates
    public transitionItemLoading: boolean;

    public activeVariant: VariantModel;
    public publicationVariants: VariantModel[];

    private announcedActiveTab: TabBarItemModel;

    private onDestroySubject = new Subject<void>();

    constructor(private router: Router,
                private route: ActivatedRoute,
                private workflowConfigurationService: WorkflowConfigurationsService,
                private publicationsService: PublicationsService,
                private socketService: SocketService,
                private userIsAllowedToPipe: UserIsAllowedToPipe,
                private customWorkflowService: CustomWorkflowService,
                private fullModalService: FullModalService,
                private dialogService: NucDialogService,
                private publicationItemSelectionService: PublicationItemSelectionService,
                private monitoredTransitionsService: MonitoredTransitionsService,
                private containerRef: ViewContainerRef,
                private variantService: VariantService,
                private userStorageService: UserSettingsStorageService) {
        super();
    }

    public ngOnInit(): void {
        // thanks to the resolvers
        this.publication = this.route.snapshot.data.publication;
        this.campaign = this.route.parent.snapshot.data.campaign;

        this.getWorkflowData();
    }

    private getWorkflowData(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_WORKFLOW_CONFIGURATION)) return;
        // Redirect query params
        const action = this.route.snapshot.queryParams['action'];
        const actionData = this.route.snapshot.queryParams['data'];

        // Subscribe to events for this publication
        this.publicationsService.registerForPublicationUpdates(this.publication._id);

        forkJoin({
            workflow: this.workflowConfigurationService.findOne(this.publication.workflow._id),
            variants: this.variantService.getVariants(this.campaign._id),
            // If we need to get the publication item, on error show message and continue to default view
            publicationItem: action === AppConstants.DEEPLINK_ACTIONS.WORKFLOW_PUBLICATION_ITEM ?
                this.publicationsService.getPublicationItem(this.publication._id, actionData)
                    .pipe(catchError((error) => {
                        Toaster.handleApiError(error);
                        return of(null);
                    })) : of(null)
        }).subscribe(
            ({workflow, variants, publicationItem}) => {
                // TODO: Remove this and create subscription to the customWorkflowService in all custom-workflow components
                this.redirectPublicationItem = publicationItem;

                this.workflow = workflow;
                this.variants = variants.items.filter((variant) => {
                    return this.publication.variants?.includes(variant._id);
                });

                if (this.variants.length > 0) {
                    const storedVariant = this.userStorageService.loadSettings(this.WORKFLOW_VARIANT_SETTING, VariantModel);
                    if (storedVariant && this.variants.find((variant) => variant._id === storedVariant._id)) {
                        this.customWorkflowService.setActiveVariant(storedVariant);
                    } else {
                        this.customWorkflowService.setActiveVariant(this.variants[0]);
                    }

                    if (Array.isArray(this.publication.variants) && this.publication.variants.length) {
                        this.publicationVariants = this.variants.filter(variant =>
                            this.publication.variants.find(pubVariant => pubVariant === variant._id));
                        this.customWorkflowService.setPublicationVariants(this.publicationVariants);
                    }
                }
                // Now vars are set, we can subscribe to events
                this.createSubscriptions();

                // We need to redirect to a publication item, make it already the selected item
                if (this.redirectPublicationItem) {
                    this.publicationItemSelectionService.setRedirectPublicationItemId(this.redirectPublicationItem._id);
                }

                // Get step for navigation (from redirect item, matrix param of the first step of the workflow)
                const componentId = this.route.snapshot.params['component'];
                // Check user is authorized for the components step
                const stepForComponent = this.workflow.steps.find((step) => step.components.find((component) => component._id === componentId) &&
                    this.userIsAllowedToPipe.transform(step.permissions));

                // priority: publicationItem > component > step
                const goToStepId = this.redirectPublicationItem?.step._id || stepForComponent?._id || this.route.snapshot.params['step'];
                // Check user is authorized for the step
                const activeStep = this.workflow.steps.find(step => step._id === goToStepId && this.userIsAllowedToPipe.transform(step.permissions))
                    || this.getFirstAllowedWorkflowStep();

                this.customWorkflowService.campaign = this.campaign;
                this.customWorkflowService.setPublication(this.publication);
                this.customWorkflowService.setWorkflow(this.workflow);
                this.customWorkflowService.setActiveStep(activeStep);
                this.stickyLogComponent =
                    this.workflow.components.find((component) => component.componentType === ECustomWorkflowComponentType.STICKY_LOG);
                this.overviewComponent =
                    this.workflow.components.find((component) => component.componentType === ECustomWorkflowComponentType.OVERVIEW);

                const filters = new Map<CustomWorkflowFilterModel, CustomWorkflowFilterOptionModel[]>();
                this.customWorkflowService.setActiveFilters(filters);

                // TODO: Make a more elegant solution for this deeplink action
                if (action === AppConstants.DEEPLINK_ACTIONS.STICKYLOG_COMMENTS) {
                    this.handleStickyLogCommentDeeplink();
                }
                // The deeplink action doesn't have to be added to the history, so navigate with replaceUrl
                // to remove queryParams from url without adding to history stack
                if (action) {
                    this.router.navigate([], {replaceUrl: true});
                }
            },
            (error) => Toaster.handleApiError(error));
    }

    private getFirstAllowedWorkflowStep(): CustomWorkflowStepModel {
        return this.workflow.steps.find(step => this.userIsAllowedToPipe.transform(step.permissions));
    }

    private createSubscriptions(): void {
        this.route.paramMap.pipe(takeUntil(this.onDestroySubject))
            .subscribe((params: ParamMap) => {
                const stepToNavigate = params.get('step');
                if (stepToNavigate && this.workflow && this.activeStep?._id !== stepToNavigate) {
                    const activeStep = this.workflow.steps.find(step => step._id === stepToNavigate) || this.getFirstAllowedWorkflowStep();
                    this.customWorkflowService.setActiveStep(activeStep);
                }
            });

        // Respond to transition item updates & filter changes to refresh the counts
        const transitionItemUpdates$: Observable<TransitionItemModel> = this.socketService.publicationUpdates$.pipe(
            debounceTime(SocketService.MESSAGES_DEBOUNCE_TIME),
            map(message => Deserialize(message.data, TransitionItemModel)),
            startWith({} as TransitionItemModel),
            takeUntil(this.onDestroySubject)
        );

        combineLatest([transitionItemUpdates$, this.customWorkflowService.activeFilters$])
            .pipe(
                switchMap(([, activeFilters]) =>
                    forkJoin({
                        activeFilters: of(activeFilters),
                        counts: this.publicationsService.getItemsCountForPublication(this.publication._id, activeFilters)
                    })
                ),
                takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result) => {
                    this.activeFilters = result.activeFilters;
                    this.counts = result.counts;
                },
                error: Toaster.handleApiError
            });

        transitionItemUpdates$.pipe(takeUntil(this.onDestroySubject))
            .subscribe(transitionItem => {
                if ([ETransitionStatus.FAILED, ETransitionStatus.DONE].includes(transitionItem.status)) {
                    this.customWorkflowService.setTransitionItemUpdate(transitionItem);

                    this.monitoredTransitionsService.removeMonitoredItem(transitionItem._id);
                }
            });

        // Respond to step changes
        this.customWorkflowService.activeStep$.pipe(
            takeUntil(this.onDestroySubject)
        ).subscribe((activeStep) => {
            this.activeStep = activeStep;
            this.isStepDisabled = !activeStep || !this.userIsAllowedToPipe.transform(activeStep.permissions);
            if (this.activeStep) {
                this.tabs = this.activeStep.components.map((component, index) => new TabBarItemModel(component.name, index));
                this.workflowActions = this.workflow.actions.filter((action) =>
                    this.workflow.transitions.find((transition) => action.transition === transition._id && transition.from === this.activeStep._id) &&
                    this.userIsAllowedToPipe.transform(action.permissions)
                ).sort((action1, action2) => {
                    const transition1 = this.workflow.transitions.find((transition) => transition._id === action1.transition);
                    const transition2 = this.workflow.transitions.find((transition) => transition._id === action2.transition);
                    const index1 = this.workflow.steps.findIndex((step) => step._id === transition1.to);
                    const index2 = this.workflow.steps.findIndex((step) => step._id === transition2.to);
                    return index1 - index2;
                });
                // Navigate to the PREVIEW tab when we redirect to a publication item
                let previewComponentIndex: number;
                if (this.redirectPublicationItem) {
                    previewComponentIndex = this.activeStep.components.findIndex(
                        (component) => component.componentType === ECustomWorkflowComponentType.PREVIEW);
                    this.redirectPublicationItem = null; // Only once
                }

                // handle componentId from url
                const componentId = this.route.snapshot.params['component'];
                const componentIndex = this.activeStep.components.findIndex((component) => component._id === componentId);

                // priority: publicationItem(always prefer preview component) > component from url > first component in the step
                this.announcedActiveTab = this.tabs[previewComponentIndex] || this.tabs[componentIndex] || this.tabs[0];
                this.customWorkflowService.setActiveComponent(activeStep.components[this.tabs.indexOf(this.announcedActiveTab)]);
            }

            this.updateUrl();
        });

        this.monitoredTransitionsService.workflowStepItemsChangedSubject$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((transitionItem) => {
                const transition = this.workflow.transitions.find(item => item._id === transitionItem.transition);
                if (transition && this.activeStep && (transition.from === this.activeStep._id || transition.to === this.activeStep._id)) {
                    const toastData = new ToastDataModel(EToastType.NEUTRAL, 'Transition',
                        'Some publication items are removed from or added to this step. ' +
                        'Click on the workflow step indicator icon to update the items.',
                        false, TIME_OUT_TYPES.LONG);
                    Toaster.openToast(toastData);
                }
            });

        this.customWorkflowService.activeComponent$.pipe(takeUntil(this.onDestroySubject)).subscribe((component) => {
            this.component = component;
            this.activeTab = this.announcedActiveTab;
            this.announcedActiveTab = null;
            this.updateUrl();
        });

        this.customWorkflowService.activeVariant$.pipe(takeUntil(this.onDestroySubject)).subscribe((variant) => {
            this.activeVariant = variant;
        });
    }

    public ngOnDestroy(): void {
        const publicationId = this.route.snapshot.params['publication_id'];

        this.publicationsService.unregisterForPublicationUpdates(publicationId);

        this.onDestroySubject.next();
        this.onDestroySubject.complete();
    }

    public onRequestTabChanged(requestedTab: TabBarItemModel): void {
        if (this.activeTab !== requestedTab) {
            this.announcedActiveTab = requestedTab;
            const component = this.activeStep.components[this.tabs.indexOf(requestedTab)];
            this.customWorkflowService.setActiveComponent(component);
        }
    }

    /**
     * Returns the number of items for the current step and filters
     * @returns {number}
     */
    public getFilteredItemCount(): number {
        if (this.counts && this.counts.steps && this.activeStep) {
            const stepCounts = this.counts.steps.find((step) => step._id === this.activeStep._id);
            return stepCounts && stepCounts.filteredIds ? stepCounts.filteredIds.length : 0;
        }
        return 0;
    }

    /**
     * Handles a deeplink to the sticky log comments
     * Opens the sticky log for the stickyId and shows comments tab if allowed
     */
    private handleStickyLogCommentDeeplink(): void {
        if (this.userIsAllowedToPipe.transform([AppConstants.PERMISSIONS.GET_STICKY_NOTES_LOG,
            AppConstants.PERMISSIONS.GET_STICKY_NOTE_COMMENTS]) && this.stickyLogComponent) {
            // TODO: Go to selected sticky note after modal opened
            this.openStickyLog();
        } else {
            Toaster.warn('You don\'t have the correct permissions to view the sticky log comments');
        }
    }

    /**
     * Responds to action clicks
     * Schedules a transition item
     * @param {CustomWorkflowActionModel} action
     */
    public onTransitionActionClicked(action: CustomWorkflowActionModel): void {
        const filteredItemCount = this.getFilteredItemCount();
        if (filteredItemCount === 0) {
            return;
        }

        const message = this.activeFilters && this.activeFilters.size > 0 ?
            `You will move ${filteredItemCount} filtered items ${action.title}` :
            `You will move all items in this step ${action.title}`;
        this.customWorkflowService.canClose().pipe(take(1), takeUntil(this.onDestroySubject))
            .subscribe(canClose => {
                if (canClose) {
                    this.openDialog(action, message);
                }
            });
    }

    private openDialog(action: CustomWorkflowActionModel, message: string): void {
        const dialogConfig = new NucDialogConfigModel('Are you sure?', message);
        const dialog = this.dialogService.openDialog(dialogConfig);
        dialogConfig.addAction('Cancel', BUTTON_TYPE.SECONDARY).subscribe(() => dialog.close());
        dialogConfig.addAction('Ok', BUTTON_TYPE.PRIMARY).subscribe(() => {
            dialog.close();
            this.postTransitionItems(action);
        });
    }

    public postTransitionItems(action: CustomWorkflowActionModel): void {
        // Items could already be transitioned by another user, so double check here
        const filteredItemCount = this.getFilteredItemCount();
        if (filteredItemCount === 0) {
            Toaster.warn(`There are no items to move in this step ${action.title}`);
            return;
        }

        this.transitionItemLoading = true;
        const transition = this.workflow.transitions.find((foundTransition) => foundTransition._id === action.transition);
        const stepCounts = this.counts.steps.find((step) => step._id === this.activeStep._id);
        this.publicationsService.postTransitionItem(this.publication._id, transition._id, {itemIds: stepCounts.filteredIds})
            .pipe(finalize(() => this.transitionItemLoading = false))
            .subscribe({
                next: () => this.publicationItemSelectionService.resetState(),
                error: (error) => Toaster.handleApiError(error)
            });
    }

    public openOverview(): void {
        const modalData: IWorkflowModalData = {
            publication: this.publication,
            workflow: this.workflow,
            component: this.overviewComponent
        };
        const config = new FullModalConfig('Overview', 'Manage your publication items.', modalData);
        // To use providers of this component, we need to configure the viewContainerRef to use this component its injector
        config.viewContainerRef = this.containerRef;

        this.fullModalService.open(CustomWorkflowOverviewComponent, config).afterClosed()
            .subscribe(() => {
                if (this.publicationItemSelectionService.itemsChanged) {
                    this.publicationItemSelectionService.onWorkflowLayoutItemsChange();
                }
            });
    }

    public openStickyLog(): void {
        const modalData: ICustomWorkflowStickyLogModalData = {
            publication: this.publication,
            workflow: this.workflow,
            component: this.stickyLogComponent,
            isStickyLog: true,
            activeVariant: this.activeVariant,
            publicationVariants: this.publicationVariants
        };

        const config = new FullModalConfig(
            'Note log',
            'View all present and past note messages of this publication.',
            modalData);

        this.fullModalService.open(CustomWorkflowStickyLogComponent, config);
    }

    private updateUrl(): void {
        this.router.navigate([this.createMatrixUrl()], {relativeTo: this.route});
    }

    private createMatrixUrl(): MatrixUrlWorkflowParams {
        return new MatrixUrlWorkflowParams(this.activeStep?._id, this.component?._id);
    }

    public close(): Observable<boolean> {
        return this.customWorkflowService.canClose();
    }

    public setVariant(variant: VariantModel): void {
        this.customWorkflowService.setActiveVariant(variant);
        this.userStorageService.storeSettings(this.WORKFLOW_VARIANT_SETTING, variant);
    }

    public setActiveStep(event: CustomWorkflowStepModel): void {
        this.customWorkflowService.setActiveStep(event);
    }

    public handleHeader(): void {
        this.headerVisible = !this.headerVisible;
    }
}
