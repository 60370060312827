<form class="fragment-form" [formGroup]="formGroup">
    <nuc-form-field label="Type">
        <nuc-dropdown formControlName="type"
                      placeholder="Select a type"
                      [nullOption]="false"
                      [total]="fragmentTypeOptions.length"
                      [items]="fragmentTypeOptions"
                      [required]="true">
        </nuc-dropdown>
    </nuc-form-field>

    <nuc-form-field *ngIf="formGroup.controls.fixedValue" label="Value">
        <nuc-input formControlName="fixedValue" placeholder="Enter the value" required></nuc-input>
    </nuc-form-field>

    <nuc-form-field *ngIf="formGroup.controls.property" label="Property">
            <property-control
                [variantRequired]="true"
                formControlName="property"
                [variants]="variants"
                [ruleProperties]="properties" />
    </nuc-form-field>
</form>
