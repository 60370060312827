import {Component, inject, ViewChild} from '@angular/core';
import {EColumnSize, EColumnType, IActionClickEvent, ITableAction, ITableColumn, ITableItemPatchModel, TableComponent} from '@relayter/rubber-duck';
import {forkJoin, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PackagesService} from '../../../../../../api/services/packages.service';
import {Toaster} from '../../../../../../classes/toaster.class';
import {PackageModel} from '../../../../../../models/api/package.model';
import {AppConstants} from '../../../../../../app.constants';
import {PublicationItemModel} from '../../../../../../models/api/publication-item.model';
import {PackageSetupCampaignDataFieldModel, PackageSetupDataFieldModel} from '../../../../../../models/api/package-setup.model';
import {unlockedBadgeAnimation} from '../../../../../../animations/unlocked-badge-animation';
import {TableInlineEditingUtil} from '../../../../../../classes/table-inline-editing.util';
import {PackageItemPostModel} from '../../../../../../models/api/package-item.model';
import {CustomWorkflowActionModel} from '../../../../../../models/api/custom-workflow-action.model';
import {CustomWorkflowBaseComponent} from '../custom-workflow-base.component';
import {PaginatorService} from '../../../../../../components/paginator/paginator.service';
import {EPropertyContext, PropertyService} from '../../../../../../api/services/property.service';
import {RulePropertyModel} from '../../../../../../models/api/rule-property.model';
import {IDataFieldTableColumn} from '../../../../../../models/interfaces/datafield-table-column.interface';
import {DataFieldsComponentUtil} from '../../../../../../classes/data-fields-component.util';
import {VariantService} from '../../../../../../api/services/variant.service';
import {VariantModel} from '../../../../../../models/api/variant.model';
import {RulePropertyUtil} from '../../../../../../classes/rule-property.util';
import {TableSortOptions} from '../../../../../../api/table-sort-options';
import {PublicationsApiService} from '../../../../../../api/services/publications.api.service';
import {NewCursorArray} from '../../../../../../api/new-api-cursor';
import {UserSettingsStorageService} from '../../../../../../api/services/user-settings-storage.service';

enum EComponentActions {
    ADD_OR_EDIT_PACKAGE = 'ADD_OR_EDIT_PACKAGE',
    ADD_TO_PACKAGE = 'ADD_TO_PACKAGE',
    REMOVE_FROM_PACKAGE = 'REMOVE_FROM_PACKAGE'
}

@Component({
    selector: 'custom-workflow-package-management-component',
    templateUrl: './custom-workflow-package-management.component.html',
    styleUrls: ['./custom-workflow-package-management.component.scss'],
    animations: [unlockedBadgeAnimation.unlockBadge],
    providers: [PaginatorService]
})
export class CustomWorkflowPackageManagementComponent extends CustomWorkflowBaseComponent {
    private static readonly TABLE_ID = 'custom-workflow-package-management-table';
    private static readonly FIELD_PREFIX = 'packageItem.dataFields.';
    private static readonly FIELD_UPDATE_PREFIX = 'packageItem.';
    private static readonly COMPONENT_TABLE_ACTIONS = [
        EComponentActions.REMOVE_FROM_PACKAGE, EComponentActions.ADD_TO_PACKAGE];
    public tableId: string; // table id changes per package which doesn't make sense for pagination
    public viewId = CustomWorkflowPackageManagementComponent.TABLE_ID; // for pagination

    public readonly storageKey: string;

    public addOrEditPackage: CustomWorkflowActionModel;

    public packages: PackageModel[];
    public selectedPackage: PackageModel;
    public totalPackages: number;
    public subscription: Subscription;
    public tableLocked = true;

    public items: PublicationItemModel[] = [];
    public columns: ITableColumn[] = [];
    public tableActions: ITableAction[];
    public pageSize: number;
    public pageIndex: number;
    private cursorArray: NewCursorArray;
    public disableNextPage = true;
    private properties: RulePropertyModel[] = [];
    private paginationSubscription: Subscription;
    private variants: VariantModel[] = [];
    protected noPackagesAvailable = true;
    private dataFieldsComponentUtil: DataFieldsComponentUtil;

    public tableSortOptions = new TableSortOptions();
    @ViewChild(TableComponent) public tableComponent: TableComponent;

    private permanentColumns: ITableColumn[] = [{
        title: '',
        selectionColumnTitle: 'Thumbnail',
        key: 'files',
        type: EColumnType.THUMBNAIL,
        format: (value) => PublicationItemModel.getThumbnailUrl(this.activeVariant?._id, value),
        clickable: true
    }, {
        title: 'Filename',
        key: 'publicationItemId',
        sortProperty: 'publicationItemId',
        size: EColumnSize.BASE
    }];

    private pageNumberColumn: ITableColumn = {
        title: 'Page numbers',
        key: 'formattedPageNumbers',
        type: EColumnType.DEFAULT,
        sortProperty: 'firstPageNumber'
    };

    private selectionInfoColumn: ITableColumn = {
        title: 'In package',
        key: 'packageItem._id',
        type: EColumnType.ICON,
        iconClass: (value) => value ? 'nucicon_check_round_fill' : '',
        format: () => '',
        color: AppConstants.FIRST_BRAND_COLOR
    };

    constructor(private packageService: PackagesService,
                private paginatorService: PaginatorService,
                private userSettingsStorageService: UserSettingsStorageService,
                private propertyService: PropertyService,
                private variantService: VariantService,
                private publicationsApiService: PublicationsApiService) {
        super();
        this.storageKey = this.userSettingsStorageService.getPrefixStorageKey();
        this.dataFieldsComponentUtil = inject(DataFieldsComponentUtil);
    }

    protected initComponent(): void {
        this.cursorArray = new NewCursorArray(this.pageIndex);

        this.listenToPagination();

        // For the campaign package table columns, we need to get all variants and the data field properties of
        // a campaign item data field from the package setup
        forkJoin([
            this.propertyService.getProperties(EPropertyContext.PACKAGE_SETUP_DATA),
            this.variantService.getVariants()]
        )
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: ([propertiesResult, variantResult]) => {
                    this.properties = propertiesResult.items;
                    this.variants = variantResult.items;
                    super.initComponent();
                },
                error: Toaster.handleApiError
            });
    }

    protected setupData(): void {
        this.tableLocked = true;

        if (this.selectedPackage) { // DO NOT reset the selected package
            this.resetPageIndex();
        } else {
            this.packages = [];
            this.columns = [];
            this.getPackages();
        }
    }

    private listenToPagination(): void {
        this.paginationSubscription?.unsubscribe();

        this.paginationSubscription = this.paginatorService.getPagination(this.viewId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) {
                    this.cursorArray = new NewCursorArray(pagination.pageIndex, this.tableSortOptions);
                }
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;
                this.getPublicationItems();
            });
    }

    private resetPageIndex(): void {
        this.paginatorService.setPageIndex(this.viewId, 1);
    }

    protected updateActions(): void {
        super.updateActions();

        this.addOrEditPackage = this.findWorkflowActionByName(EComponentActions.ADD_OR_EDIT_PACKAGE);

        // add the table actions specifically for this component
        const packageTableActions = this.allowedActions
            .filter((action) => CustomWorkflowPackageManagementComponent.COMPONENT_TABLE_ACTIONS
                .includes(action.name as EComponentActions))
            .map((action) => ({title: action.title, icon: action.icon} as ITableAction));

        this.tableActions = [...packageTableActions, ...this.getTableActions()];
    }

    public getPackages(event = null): void {
        if (event?.reset) this.packages = [];

        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_PACKAGES)) return;
        if (this.subscription) this.subscription.unsubscribe();
        this.subscription = this.packageService.getPackagesForCampaign(this.campaign._id,
            AppConstants.PAGE_SIZE_DEFAULT, event?.offset, null, 'name', 'asc', event?.search)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (result) => {
                    this.totalPackages = result.total;
                    this.packages = this.packages.concat(result.items);
                    if (this.packages.length && !this.selectedPackage) { // initialize the value
                        this.campaignPackageChanged(this.packages[0]);
                    }
                    this.noPackagesAvailable = (this.noPackagesAvailable && this.packages.length === 0);
                },
                error: Toaster.handleApiError
            });
    }

    public campaignPackageChanged(campaignPackage: PackageModel): void {
        if (this.selectedPackage?._id !== campaignPackage?._id) {
            this.selectedPackage = campaignPackage;
            this.tableId = `${CustomWorkflowPackageManagementComponent.TABLE_ID}-${this.selectedPackage._id}`;

            this.updateTableColumns();
            this.resetPageIndex();
        }
    }

    private getDataFieldsColumns(packageDataFields: PackageSetupDataFieldModel[]): ITableColumn[] {
        const materialDataColumns = this.dataFieldsComponentUtil.getDataFieldsColumnSelection(
            packageDataFields.filter(packageDataField => !(packageDataField instanceof PackageSetupCampaignDataFieldModel))
                .map(dataField => {
                    return {
                        name: dataField.name,
                        fieldName: dataField.fieldName,
                        dataType: {
                            type: dataField.dataType.type
                        }
                    } as IDataFieldTableColumn;
                }), true, null, CustomWorkflowPackageManagementComponent.FIELD_PREFIX);

        let useActiveVariantFromPublication = true;

        const campaignDataFields = packageDataFields.filter(packageDataField => packageDataField instanceof PackageSetupCampaignDataFieldModel)
            .map(dataField => {
                const path = (dataField as PackageSetupCampaignDataFieldModel).property;
                const {ruleProperty, variant} = RulePropertyUtil.findRulePropertyAndVariantByPath(path, this.variants, this.properties);
                // when we use one variant from packageSetup campaign data, we don't use activeVariant from publication anymore
                if (variant) useActiveVariantFromPublication = false;
                return {
                    name: dataField.name,
                    fieldName: dataField.fieldName,
                    dataType: ruleProperty?.dataType,
                    enableVariants: !!ruleProperty?.enableVariants
                } as IDataFieldTableColumn;
            });

        const campaignDataFieldColumns = this.dataFieldsComponentUtil.getDataFieldsColumnSelection(
            campaignDataFields,
            false,
            useActiveVariantFromPublication ? this.activeVariant?.key : null,
            CustomWorkflowPackageManagementComponent.FIELD_PREFIX);

        return [...materialDataColumns, ...campaignDataFieldColumns];
    }

    private getPublicationItems(): void {
        if (!this.userIsAllowedToPipe.transform(this.permissions.GET_PUBLICATION_ITEMS) || !this.selectedPackage) return;
        if (this.subscription) this.subscription.unsubscribe();

        this.subscription =
            this.publicationsApiService.getPublicationItemsForPackageInfo(
                this.publication._id,
                this.selectedPackage._id,
                this.step._id,
                this.activeFilters,
                this.pageSize,
                0,
                this.cursorArray.getCursor(this.pageIndex),
                this.tableSortOptions)
                .pipe(takeUntil(this.onDestroySubject))
                .subscribe({
                    next: (result) => {
                        this.items = result.items;
                        this.disableNextPage = !result.hasNext;
                        if (this.items.length) {
                            this.cursorArray.setCursor(this.pageIndex, this.items[this.items.length - 1]);
                        }
                    },
                    error: Toaster.handleApiError
                });
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const action = this.allowedActions.find((allowedAction) => allowedAction.title === event.action.title);
        const item = event.item as PublicationItemModel;
        switch (action.name) {
            case EComponentActions.REMOVE_FROM_PACKAGE:
                if (item.packageItem._id) this.packageService.deletePackageItem(this.campaign._id, this.selectedPackage._id, item.packageItem._id)
                    .pipe(takeUntil(this.onDestroySubject))
                    .subscribe({
                        next: () => {
                            this.refreshData();
                        },
                        error: Toaster.handleApiError
                    });
                break;
            case EComponentActions.ADD_TO_PACKAGE:
                if (!item.packageItem._id) this.packageService.postPackageItem(this.campaign._id, this.selectedPackage._id,
                    {publication: this.publication._id, publicationItem: item._id, dataFields: {}} as PackageItemPostModel)
                    .pipe(takeUntil(this.onDestroySubject))
                    .subscribe({
                        next: () => {
                            this.refreshData();
                        },
                        error: Toaster.handleApiError
                    });
                break;
            default:
                this.handleAction(action, [item]);
        }
    }

    protected refreshData(): void {
        this.getPublicationItems();
    }

    public onItemEdited(patchObj: ITableItemPatchModel): void {
        const {property, value} = TableInlineEditingUtil.getPropertyAndValue(patchObj);
        const foundItem = this.items.find((item) => item._id === patchObj._id);
        const valueBackup = property.split('.').reduce((o, p) => o && o[p], foundItem);
        // modify front-end object to show user changes
        TableInlineEditingUtil.fillObjectFromPath(property, value, foundItem);
        // create a patchBody containing only the property to update. Example: {dataFields: {some-prop: 'some value'}}
        // replace the property, because update/post is for packageItem
        const updateBody = TableInlineEditingUtil.fillObjectFromPath(
            property.replace(CustomWorkflowPackageManagementComponent.FIELD_UPDATE_PREFIX, ''), value
        );

        const observable = foundItem.packageItem._id
            ? this.packageService.patchPackageItem(this.campaign._id, this.selectedPackage._id, foundItem.packageItem._id, updateBody)
            : this.packageService.postPackageItem(this.campaign._id, this.selectedPackage._id,
                {publication: this.publication._id, publicationItem: foundItem._id, ...updateBody} as PackageItemPostModel);

        observable
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe({
                next: (packageItem) => {
                    if (!foundItem.packageItem._id) { // we need to assign the packageItem id to the publicationItem
                        foundItem.packageItem._id = packageItem._id;
                    }
                },
                error: (err) => {
                    // Reset to previous value in case of error
                    TableInlineEditingUtil.fillObjectFromPath(property, valueBackup, foundItem);
                    Toaster.handleApiError(err);
                }
            });
    }

    protected activeVariantChanged() {
        this.updateTableColumns();
    }

    private updateTableColumns(): void {
        const dataFields = this.selectedPackage ?
            this.selectedPackage.packageSetup.materialDataFields.concat(this.selectedPackage.packageSetup.campaignDataFields) : [];

        this.columns = this.publication.workflow.sequentialNumbering ? this.permanentColumns.concat(this.pageNumberColumn) :
            this.permanentColumns;
        this.columns = this.columns.concat(this.getDataFieldsColumns(dataFields), this.selectionInfoColumn);

        const prevSortOrder = this.tableSortOptions.sortOrder;
        const prevSortColumns = this.tableSortOptions.columns.filter(column => this.columns.find(col => col.sortProperty === column.sortProperty));
        this.tableSortOptions = new TableSortOptions(prevSortColumns, prevSortOrder);
    }
}
