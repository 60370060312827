import {Component} from '@angular/core';
import {EColumnDataType, EColumnType, ESelectionMode, IActionClickEvent, ISortOptionEvent, ITableAction, ITableColumn} from '@relayter/rubber-duck';
import {PublicationItemModel} from '../../../../../../models/api/publication-item.model';
import {Subscription} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {Toaster} from '../../../../../../classes/toaster.class';
import {ETransitionStatus} from '../../../../../../models/api/transition-item.model';
import {CustomWorkflowBaseComponent} from '../custom-workflow-base.component';
import {DataFieldModel} from '../../../../../../models/api/data-field.model';
import {PaginatorService} from '../../../../../../components/paginator/paginator.service';
import {DataFieldsComponentUtil} from '../../../../../../classes/data-fields-component.util';
import {SelectionModel} from '@angular/cdk/collections';
import {CustomWorkflowActionModel} from '../../../../../../models/api/custom-workflow-action.model';
import {DataFieldsApiService} from '../../../../../../api/services/data-fields.api.service';
import {CustomWorkflowOptionModel, EWorkflowComponentOptionName} from '../../../../../../models/api/custom-workflow-option.model';
import {TableSortOptions} from '../../../../../../api/table-sort-options';
import {NewCursorArray} from '../../../../../../api/new-api-cursor';
import {UserSettingsStorageService} from '../../../../../../api/services/user-settings-storage.service';

@Component({
    selector: 'rl-custom-workflow-list-view-component',
    templateUrl: './custom-workflow-list-view.component.html',
    styleUrls: ['./custom-workflow-list-view.component.scss'],
    providers: [PaginatorService]
})
export class CustomWorkflowListViewComponent extends CustomWorkflowBaseComponent {
    public tableId: string;
    public readonly storageKey: string;
    public tableSelectionMode = ESelectionMode.MULTI;

    public columns: ITableColumn[] = [];
    public total: number;
    public items: PublicationItemModel[] = [];
    public tableActions: ITableAction[];

    public pageIndex: number;
    public pageSize: number;
    private cursorArray: NewCursorArray;
    public disableNextPage: boolean;
    public tableSortOptions = new TableSortOptions()
    public selection: SelectionModel<PublicationItemModel> = new SelectionModel<PublicationItemModel>(true, [], true);

    public subscription: Subscription;
    private campaignDataFields: DataFieldModel[];
    private permanentColumns: ITableColumn[] = [{
        title: '',
        selectionColumnTitle: 'Thumbnail',
        key: 'files',
        type: EColumnType.THUMBNAIL,
        format: (value) => PublicationItemModel.getTableMediaObject(value, this.activeVariant?._id),
        clickable: true
    },
    {
        title: 'Publication Item id',
        key: 'publicationItemId',
        sortProperty: 'publicationItemId',
        dataType: EColumnDataType.STRING
    }];

    private pageNumberColumn: ITableColumn = {
        title: 'Page numbers',
        key: 'formattedPageNumbers',
        type: EColumnType.DEFAULT,
        dataType: EColumnDataType.NUMBER,
        sortProperty: 'firstPageNumber'
    };
    private idSortColumn: ITableColumn = {
        title: 'Id',
        key: '_id',
        dataType: EColumnDataType.STRING,
        sortProperty: '_id'
    };

    private paginationSubscription: Subscription;
    private selectionSubscription: Subscription;
    private populatePaths: string[];
    private columnConfig: CustomWorkflowOptionModel;

    constructor(private userSettingsStorageService: UserSettingsStorageService,
                private dataFieldService: DataFieldsApiService,
                private paginatorService: PaginatorService,
                private dataFieldsComponentUtil: DataFieldsComponentUtil) {
        super();
        this.storageKey = this.userSettingsStorageService.getPrefixStorageKey();
    }

    public initComponent(): void {
        super.initComponent();

        this.customWorkflowService.transitionItemUpdate$
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((transitionItem) => { // Refresh on failed transition
                // Failed items in current step no longer in transition
                const transition = this.workflow.transitions.find(item => item._id === transitionItem.transition);
                if (transition && transitionItem.status === ETransitionStatus.FAILED && this.step._id === transition.from) {
                    this.setPageIndex();
                }
            });
    }

    protected setupData(): void {
        this.tableSortOptions.columns = [this.publication.workflow.sequentialNumbering ? this.pageNumberColumn : this.idSortColumn];
        this.columnConfig = this.component.options?.find((option) => option.name === EWorkflowComponentOptionName.COLUMN_CONFIG);

        // Set paths to populate
        this.populatePaths = [];
        if (this.columnConfig?.value?.find(val => val.property.match(/^template/))) {
            this.columnConfig.value.find(val => val.property.match(/^template\.templateType/)) ? this.populatePaths.push('template.templateType') :
                this.populatePaths.push('template');
        }
        if (this.columnConfig?.value?.find(val => val.property.match(/^campaignItems/))) {
            this.populatePaths.push('campaignItems');
        }

        // List views have a component based custom columns configuration
        this.tableId = `custom-workflow-list-view-table-${this.component._id}`;

        // To avoid we have multiple subscriptions open
        if (this.paginationSubscription) this.paginationSubscription.unsubscribe();

        this.paginationSubscription = this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntil(this.onDestroySubject))
            .subscribe((pagination) => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) {
                    this.cursorArray = new NewCursorArray(pagination.pageIndex, this.tableSortOptions);
                }
                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;
                this.getPublicationItems();
            });

        this.setPageIndex();

        this.selection.clear();

        // To avoid we have multiple subscriptions open
        if (this.selectionSubscription) this.selectionSubscription.unsubscribe();

        // Only call once
        if (!this.campaignDataFields) {
            this.dataFieldService.getCampaignItemDataFields()
                .pipe(
                    take(1),
                    takeUntil(this.onDestroySubject)
                )
                .subscribe(dataFields => {
                    this.campaignDataFields = dataFields;
                    this.setupTable();
                });
        } else {
            this.setupTable();
        }
    }

    protected refreshData(transitionItemId: string): void { // called after action is triggered
        transitionItemId ? this.setPageIndex() : this.getPublicationItems();
    }

    private getPublicationItems(): void {
        if (this.subscription) this.subscription.unsubscribe();

        this.subscription = this.publicationItemsApiService.getItemsForPublication(
            this.publication._id,
            this.step?._id,
            this.activeFilters,
            this.pageSize,
            0,
            this.cursorArray.getCursor(this.pageIndex),
            this.tableSortOptions,
            false,
            this.populatePaths
        ).subscribe({
            next: result => {
                this.items = result.items;
                this.disableNextPage = !result.hasNext;

                if (this.items.length > 0) {
                    this.cursorArray.setCursor(this.pageIndex, this.items[this.items.length - 1]);
                }
            },
            error: Toaster.handleApiError
        });
    }

    private setupTable(): void {
        // Need to wait for the data fields
        if (!this.campaignDataFields) {
            return;
        }

        // table columns
        this.columns = this.publication.workflow.sequentialNumbering ? this.permanentColumns.concat(this.pageNumberColumn) : this.permanentColumns;
        this.columns = this.columns.concat(this.dataFieldsComponentUtil.getDataFieldsColumnList(this.campaignDataFields, this.columnConfig,
            this.activeVariant?.key));
        this.tableActions = this.getTableActions();
    }

    public handleTableRowAction(event: IActionClickEvent): void {
        const action = this.allowedActions.find((allowedAction) => allowedAction.title === event.action.title);
        this.handleAction(action, [event.item as PublicationItemModel]);
    }

    protected activeVariantChanged() {
        this.setupTable();
    }

    private setPageIndex(pageIndex = 1): void {
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    public onSortOptionChange(sortEvent: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(sortEvent);
        this.setPageIndex();
    }

    public handleMultiSelectionAction(action: CustomWorkflowActionModel): void {
        this.handleAction(action, this.selection.selected);
        this.selection.clear();
    }
}
