// Modules
/* eslint-disable max-len */
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
    MediaCardViewComponent,
    MentionInputComponent,
    NUCPipesModule,
    RDModule,
    SwitchComponent,
    VideoPlayerComponent
} from '@relayter/rubber-duck';
import {
    CustomWorkflowBriefingComponent
} from './custom-workflow-briefing/custom-workflow-briefing.component';
import {
    CustomWorkflowStickyListComponent
} from './custom-workflow-sticky-list/custom-workflow-sticky-list.component';
import {
    CustomWorkflowStickyLogComponent
} from './custom-workflow-sticky-list/custom-workflow-sticky-log.component';
import {
    CustomWorkflowFilesDownloadComponent
} from './custom-workflow-files/custom-workflow-files-download/custom-workflow-files-download.component';
import {
    CustomWorkflowFilesUploadComponent
} from './custom-workflow-files/custom-workflow-upload/custom-workflow-files-upload.component';
import {
    CustomWorkflowFilesComponent
} from './custom-workflow-files/custom-workflow-files.component';
import {
    FilesDownloadPackageTypeComponent
} from './custom-workflow-files/custom-workflow-files-download/package-type/files-download-package-type.component';
import {
    FilesDownloadItemSelectionComponent
} from './custom-workflow-files/custom-workflow-files-download/items/files-download-item-selection.component';
import {
    CustomWorkflowFiltersComponent
} from './custom-workflow-filters/custom-workflow-filters.component';
import {
    CustomWorkflowLayoutComponent
} from './custom-workflow-layout/custom-workflow-layout.component';
import {
    CustomWorkflowAddPagesComponent
} from './custom-workflow-add-pages/custom-workflow-add-pages.component';
import {
    CustomWorkflowItemSelectionComponent
} from './custom-workflow-item-selection/custom-workflow-item-selection.component';
import {
    CustomWorkflowListViewComponent
} from './custom-workflow-list-view/custom-workflow-list-view.component';
import {
    CustomWorkflowPackageManagementComponent
} from './custom-workflow-package-management/custom-workflow-package-management.component';
import {
    CustomWorkflowAutomationComponent
} from './custom-workflow-automation/custom-workflow-automation.component';
import {StickyCommentComponent} from '../../../../../components/sticky-note/sticky-comment/sticky-comment.component';
import {RelinkStickyNoteComponent} from '../../../../../components/relink-sticky-note/relink-sticky-note.component';
import {
    WorkflowTabBarViewComponent
} from '../../../../../components/tab-bar-view-view/workflow/workflow-tab-bar-view.component';
import {
    CustomWorkflowPreviewComponent
} from './custom-workflow-preview/custom-workflow-preview.component';
import {
    PreviewStickyNotesViewComponent
} from './custom-workflow-preview/preview-sticky-notes-view/preview-sticky-notes-view.component';
import {
    CustomWorkflowOverviewComponent
} from './custom-workflow-overview/custom-workflow-overview.component';
import {
    CustomWorkflowAssignableItemsOverviewComponent
} from './custom-workflow-item-actions/custom-workflow-assignable-items-overview/custom-workflow-assignable-items-overview.component';
import {
    CustomWorkflowSelectionModalComponent
} from './custom-workflow-briefing-actions/custom-workflow-selection-modal/custom-workflow-selection-modal.component';
import {
    PreviewStickyNotesSidebarComponent
} from './custom-workflow-preview/preview-sticky-notes-sidebar/preview-sticky-notes-sidebar.component';
import {
    PreviewBriefingChangesComponent
} from './custom-workflow-preview/preview-briefing-changes/preview-briefing-changes.component';
import {
    CustomWorkflowOverviewItemsComponent
} from './custom-workflow-overview/custom-workflow-overview-items/custom-workflow-overview-items.component';
import {
    CustomWorkflowOverviewDeletedItemsComponent
} from './custom-workflow-overview/custom-workflow-overview-deleted-items/custom-workflow-overview-deleted-items.component';
import {
    CustomWorkflowUploadAssetsComponent
} from './custom-workflow-upload-assets/custom-workflow-upload-assets.component';
import {DownloadPackageComponent} from '../../../../../components/download-package/download-package.component';
import {
    CustomWorkflowUploadComponent
} from './custom-workflow-upload/custom-workflow-upload.component';
import {PipesModule} from '../../../../../pipes/pipes.module';
import {FormComponentsModule} from '../../../../../forms/form-components.module';
import {ComponentsModule} from '../../../../../components/components.module';
import {MatRippleModule} from '@angular/material/core';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {
    PublicationItemFormComponent
} from './custom-workflow-item-actions/publication-item-form/publication-item-form.component';
import {
    LayoutNoteFormComponent
} from './custom-workflow-layout/spread-editor/note/layout-note-form/layout-note-form.component';
import {
    AssignToPackageFormComponent
} from './custom-workflow-item-actions/assign-to-package-form/assign-to-package-form.component';
import {
    AssignToPackagePackagesFormComponent
} from './custom-workflow-item-actions/assign-to-package-form/assign-to-package-packages-form/assign-to-package-packages-form.component';
import {
    AssignToPackageMaterialDataFormComponent
} from './custom-workflow-item-actions/assign-to-package-form/assign-to-package-material-data-form/assign-to-package-material-data-form.component';
import {CustomWorkflowComponent} from './custom-workflow.component';
import {
    CustomWorkflowPlaceholderComponent
} from './custom-workflow-placeholder/custom-workflow-placeholder.component';
import {
    StickyNoteDetailsComponent
} from './custom-workflow-preview/preview-sticky-notes-sidebar/sticky-note-details/sticky-note-details.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatMenuModule} from '@angular/material/menu';
import {CommonModule} from '@angular/common';
import {UploadItemFilesModalFormComponent} from './custom-workflow-forms/upload-item-files-form/upload-item-files-modal-form/upload-item-files-modal-form.component';
import {UploadItemFileInputComponent} from './custom-workflow-forms/upload-item-files-form/upload-item-file-input/upload-item-file-input.component';
import {UploadItemFilesInlineFormComponent} from './custom-workflow-forms/upload-item-files-form/upload-item-files-inline-form/upload-item-files-inline-form.component';
import {CdkPortalOutlet} from '@angular/cdk/portal';
import {
    PublicationItemDetailsComponent
} from './individual-workflow-components/publication-item-details/publication-item-details.component';
import {
    ImageStickyNotesViewComponent
} from './custom-workflow-preview/preview-sticky-notes-view/image-sticky-notes-view/image-sticky-notes-view.component';
import {
    VideoStickyNotesViewComponent
} from './custom-workflow-preview/preview-sticky-notes-view/video-sticky-notes-view/video-sticky-notes-view.component';
import {NgxFilesizeModule} from 'ngx-filesize';
import {CustomWorkflowAssetsComponent} from './custom-workflow-assets/custom-workflow-assets.component';
import {DataFilterComponent} from '../../../../../components/data-filter/data-filter.component';

// Directives

// Components
/* eslint-enable max-len */

const componentsList = [
    CustomWorkflowComponent,
    CustomWorkflowPlaceholderComponent,
    CustomWorkflowBriefingComponent,
    CustomWorkflowStickyListComponent,
    CustomWorkflowStickyLogComponent,
    CustomWorkflowFilesDownloadComponent,
    CustomWorkflowFilesUploadComponent,
    CustomWorkflowFilesComponent,
    FilesDownloadPackageTypeComponent,
    FilesDownloadItemSelectionComponent,
    CustomWorkflowFiltersComponent,
    CustomWorkflowLayoutComponent,
    CustomWorkflowAddPagesComponent,
    CustomWorkflowItemSelectionComponent,
    CustomWorkflowListViewComponent,
    CustomWorkflowPackageManagementComponent,
    CustomWorkflowAutomationComponent,
    StickyNoteDetailsComponent,
    StickyCommentComponent,
    RelinkStickyNoteComponent,
    WorkflowTabBarViewComponent,
    CustomWorkflowPreviewComponent,
    PreviewStickyNotesViewComponent,
    CustomWorkflowOverviewComponent,
    CustomWorkflowAssignableItemsOverviewComponent,
    CustomWorkflowSelectionModalComponent,
    PreviewStickyNotesSidebarComponent,
    PreviewBriefingChangesComponent,
    CustomWorkflowOverviewItemsComponent,
    CustomWorkflowOverviewDeletedItemsComponent,
    CustomWorkflowUploadAssetsComponent,
    DownloadPackageComponent,
    CustomWorkflowUploadComponent,
    PublicationItemFormComponent,
    LayoutNoteFormComponent,
    AssignToPackageFormComponent,
    AssignToPackagePackagesFormComponent,
    AssignToPackageMaterialDataFormComponent,
    UploadItemFilesModalFormComponent,
    UploadItemFilesInlineFormComponent,
    UploadItemFileInputComponent
];

@NgModule({
    imports: [
        ReactiveFormsModule,
        RDModule,
        PipesModule,
        NUCPipesModule,
        FormsModule,
        FormComponentsModule,
        MatPaginatorModule,
        MatRippleModule,
        MatMenuModule,
        DragDropModule,
        ComponentsModule,
        MatTooltipModule,
        CommonModule,
        MediaCardViewComponent,
        VideoPlayerComponent,
        CdkPortalOutlet,
        PublicationItemDetailsComponent,
        ImageStickyNotesViewComponent,
        VideoStickyNotesViewComponent,
        MentionInputComponent,
        SwitchComponent,
        NgxFilesizeModule,
        CustomWorkflowAssetsComponent,
        DataFilterComponent
    ],
    declarations: [
        componentsList
    ],
    exports: [...componentsList],
    providers: []
})

export class CustomWorkflowModule {
}
