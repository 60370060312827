import {Component, EventEmitter} from '@angular/core';
import {BUTTON_TYPE, NucDialogConfigModel} from '@relayter/rubber-duck';
import {CustomWorkflowBaseComponent} from '../custom-workflow-base.component';
import {FileTypeUtil} from '../../../../../../classes/file-type.util';
import {Toaster} from '../../../../../../classes/toaster.class';
import {concatMap, filter, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {CustomWorkflowTransitionModel} from '../../../../../../models/api/custom-workflow-transition.model';
import {Observable, of, Subject} from 'rxjs';
import {ETransitionStatus, TransitionItemModel} from '../../../../../../models/api/transition-item.model';

enum EComponentActions {
    UPLOAD_ASSETS = 'UPLOAD_ASSETS'
}

@Component({
    selector: 'custom-workflow-upload-assets-component',
    templateUrl: './custom-workflow-upload-assets.component.html',
    styleUrls: ['./custom-workflow-upload-assets.component.scss']
})
export class CustomWorkflowUploadAssetsComponent extends CustomWorkflowBaseComponent {
    public fileTypeCategories = [FileTypeUtil.CATEGORIES.IMAGE, FileTypeUtil.CATEGORIES.PDF, FileTypeUtil.CATEGORIES.VIDEO];
    public transitionsInProgress: boolean;
    public uploadTransition: CustomWorkflowTransitionModel;

    private totalNumberOfAssets = 0;
    private processedKeys = 0;
    private progress = new EventEmitter<number>();
    public transitionProgress = this.progress
        .pipe(
            switchMap(() => of(Math.round(100 * this.processedKeys / this.totalNumberOfAssets))),
            startWith(0),
            takeUntil(this.onDestroySubject)
        );

    private uploadAssetsQueue = new Subject<string>();

    constructor() {
        super();
    }

    protected initComponent() {
        super.initComponent();

        const uploadAssetAction = this.allowedActions.find(action => action.name === EComponentActions.UPLOAD_ASSETS);
        this.uploadTransition = this.workflow.transitions.find((foundTransition) => foundTransition._id === uploadAssetAction?.transition);

        // Listen to added files. Handle assets one by one.
        // Post a new transition after previous transition is completed (done or failed)
        this.uploadAssetsQueue
            .pipe(
                concatMap(s3Key => this.postUploadTransition([s3Key])),
                filter(transition => [ETransitionStatus.FAILED, ETransitionStatus.DONE].includes(transition.status)),
                tap(() => {
                    this.processedKeys++;
                    if (this.totalNumberOfAssets === this.processedKeys) {
                        this.transitionsInProgress = false;
                        this.totalNumberOfAssets = 0;
                        this.processedKeys = 0;
                    }
                    this.progress.emit();
                }),
                takeUntil(this.onDestroySubject)
            )
            .subscribe({
                error: Toaster.handleApiError
            });
    }

    protected setupData(): void {
    }

    protected refreshData(): void { // called after action is triggered
    }

    public onFilesChanged(s3Keys: string[]): void {
        this.transitionsInProgress = true;
        this.totalNumberOfAssets += s3Keys.length;
        this.progress.emit();

        // Add assets to the queue
        for (const s3Key of s3Keys) {
            this.uploadAssetsQueue.next(s3Key);
        }
    }

    private postUploadTransition(s3Keys: string[]): Observable<TransitionItemModel> {
        return this.publicationsService.postTransitionItem(this.publication._id, this.uploadTransition._id, {s3Keys})
            .pipe(
                switchMap(transition => this.monitoredTransitionsService.getItemMonitor(transition._id)),
                takeUntil(this.onDestroySubject)
            );
    }

    protected requestClose() {
        if (this.transitionsInProgress) {
            const dialogConfig = new NucDialogConfigModel('Uploading assets',
                'Assets are still being uploaded. Are you sure you want to cancel the upload?');
            const dialog = this.dialogService.openDialog(dialogConfig);
            dialogConfig.addAction('No', BUTTON_TYPE.SECONDARY).subscribe(() => {
                dialog.close();
                this.closeSubscription.next(false);
            });
            dialogConfig.addAction('Yes', BUTTON_TYPE.PRIMARY).subscribe(() => {
                dialog.close();
                this.transitionsInProgress = false;
                this.closeSubscription.next(true);
            });
        } else {
            this.closeSubscription.next(true);
        }
    }
}
