<div class="detail-container">
    <p class="section-header comments-history-header">Comments</p>

    <form [formGroup]="formGroup" *ngIf="addCommentAction" (dragenter)="isDragging = true"
          (dragleave)="isDragging = false">
        <nuc-mention-input *ngIf="!isDragging"
                           [dataSource]="getUserSuggestions"
                           [ownUserId]="loggedInUser?._id"
                           formControlName="comment">
        </nuc-mention-input>

        <div class="file-input-area" *ngIf="isDragging">
            <input type="file"
                   id="file"
                   name="files-[]"
                   class="inputfile"
                   multiple="multiple"
                   (change)="onFilesChanged($event)"/>

            <label class="head-label" for="file">
                <div class="drag-drop-row">
                    <img src="/assets/images/icon_drag_drop_active.svg"/>
                </div>
                <div class="drag-drop-row">
                    <p class="text-center">Now drop it</p>
                </div>
            </label>

        </div>
        <div class="new-files" *ngIf="this.uploadingFiles?.length > 0">
            <attachment-progress-bar *ngFor="let file of uploadingFiles; index as i"
                text="{{file.filename}} - {{file.fileSize}}"
                (onDelete)="onDeleteButtonClicked(file)"
                [progress]="file.progress$ | async">
            </attachment-progress-bar>
        </div>
        <nuc-button-bar>
            <div class="file-input-button-area">
                <input class="inputfile-button" type="file" (change)="onFilesChanged($event)" multiple
                       id="file-button"/>
                <nuc-button-secondary icon="nucicon_paperclip"></nuc-button-secondary>
            </div>
            <nuc-button-primary text="Save" [disabled]="!formGroup.valid"
                                (click)="onSubmit()"
                                [loading]="loading">
            </nuc-button-primary>
        </nuc-button-bar>
    </form>

    <ng-container *ngIf="showCommentsAction?.from.includes(stickyNote.status) else noShowCommentsActionState">

        <rl-loading-indicator *ngIf="commentsLoading"></rl-loading-indicator>

        <div *ngIf="!commentsLoading">

            <div class="no-comments-container" *ngIf="!comments || comments.length === 0">
                <p>No comments</p>
            </div>

            <div class="comments" *ngFor="let comment of comments; let i = index">

                <div class="line-container" [class.first]="i === 0" [class.last]="i === comments.length - 1">
                    <div class="line"></div>

                    <!-- Single change -->
                    <img src="/assets/images/icon_tracker_bullet.svg" *ngIf="comments.length === 1"/>

                    <!-- Multiple changes -->
                    <img src="/assets/images/icon_tracker_start.svg" *ngIf="i === 0 && comments.length > 1"/>
                    <img src="/assets/images/icon_tracker_middle.svg"
                         *ngIf="i !== 0 && i !== comments.length - 1 && comments.length > 1"/>
                    <img src="/assets/images/icon_tracker_bottom.svg"
                         *ngIf="i === comments.length - 1 && comments.length > 1"/>

                    <div class="line"></div>
                </div>

                <div class="comment-container">
                    <p class="body-strong black">{{comment.createdBy?.fullName | nullUndefinedFormatter:DELETED_USER}}</p>
                    <p class="body-strong">{{comment.createdAt | RLDatePipe:dateFormats.STICKY_COMMENT}}</p>
                    <p *ngIf="comment.htmlMessage" [innerHTML]="comment.htmlMessage | safeHtml"></p>
                    <p *ngIf="!comment.htmlMessage">{{comment.message}}</p>
                    <div class="new-files">
                        <div class="file-container" *ngFor="let attachment of comment.attachments; index as i"
                             nucTooltip="{{attachment.fileName}} - {{attachment.file.size}}" (click)="downloadFile(attachment.file.url)">
                            <div class="content dark">
                                <i class="nucicon_paperclip"></i>
                                <div class="text"><span class="file-name">{{attachment.fileName}}</span> -
                                    <span>{{attachment.file.size | filesize}}</span></div>
                                <i class="nucicon_download"></i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <nuc-button-secondary text="Show more" class="centered"
                                  *ngIf="comments && comments.length < totalComments"
                                  (click)="onShowMoreButtonClicked()">
            </nuc-button-secondary>

        </div>
    </ng-container>

    <ng-template #noShowCommentsActionState>
        <nuc-empty-state-component imageUrl="assets/images/empty_states/no_permission_small.svg"
                                   [small]="true"
                                   title="You don't have permission to view this section of the workflow.">
        </nuc-empty-state-component>
    </ng-template>

</div>
