import {Component, Inject, OnInit, Optional} from '@angular/core';
import {Toaster} from '../../../../../../../classes/toaster.class';
import {ARPagedResponseDataModel} from '@relayter/core';
import {AppConstants} from '../../../../../../../app.constants';
import {Subscription} from 'rxjs';
import {ISortOptionEvent, ITableColumn, NUC_FULL_MODAL_DATA} from '@relayter/rubber-duck';
import {CustomWorkflowBaseComponent} from '../../custom-workflow-base.component';
import {IWorkflowModalData} from '../../../../../../../models/interfaces/workflow-modal-data.interface';
import {TransitionItemDataModel} from '../../../../../../../models/api/transition-item.model';
import {RLDatePipe} from '../../../../../../../pipes/rl-date.pipe';
import {PaginatorService} from '../../../../../../../components/paginator/paginator.service';
import {ECustomWorkflowTransitionRecipeTaskName} from '../../../../../../../models/api/custom-workflow-transition.model';
import {UserSettingsStorageService} from '../../../../../../../api/services/user-settings-storage.service';
import {NullUndefinedPipe} from '../../../../../../../pipes/null-undefined.pipe';
import {PublicationsApiService} from '../../../../../../../api/services/publications.api.service';
import {TableSortOptions} from '../../../../../../../api/table-sort-options';
import {NewCursorArray} from '../../../../../../../api/new-api-cursor';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
    selector: 'rl-custom-workflow-overview-deleted-items-component',
    templateUrl: './custom-workflow-overview-deleted-items.component.html',
    styleUrls: ['./custom-workflow-overview-deleted-items.component.scss'],
    providers: [PaginatorService]
})
export class CustomWorkflowOverviewDeletedItemsComponent extends CustomWorkflowBaseComponent implements OnInit  {
    public tableId = 'custom-workflow-overview-deleted-items';
    public transitionItemsData: TransitionItemDataModel[] = [];

    public columns: ITableColumn[] = [
        {
            title: 'Publication item id',
            key: 'item.publicationItemId',
            sortProperty: 'item.publicationItemId'
        },
        {
            title: 'Deleted by',
            key: 'user.fullName',
            sortProperty: 'user.fullName',
            sortDuplicates: true,
            format: (value) => NullUndefinedPipe.transform(value, NullUndefinedPipe.defaultValues.DELETED_USER)
        },
        {
            title: 'Date deleted',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED),
            sortDuplicates: true
        }
    ];
    public pageIndex = AppConstants.PAGE_INDEX_DEFAULT;
    public pageSize: number;
    public disableNextPage: boolean;
    private tableSortOptions = new TableSortOptions();
    private cursorArray: NewCursorArray = new NewCursorArray(1, this.tableSortOptions);

    public transitionItemsDataSubscription: Subscription;
    public storageKey: string;

    constructor(@Optional() @Inject(NUC_FULL_MODAL_DATA) public modalData: IWorkflowModalData,
                private publicationsApiService: PublicationsApiService,
                private paginatorService: PaginatorService,
                private userSettingsStorageService: UserSettingsStorageService) {

        super(undefined, modalData);
        this.storageKey = this.userSettingsStorageService.getPrefixStorageKey();
    }

    public initComponent(): void {
        super.initComponent();

        this.paginatorService.getPagination(this.tableId)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(pagination => {
                if (pagination.pageIndex === 1 || pagination.pageSize !== this.pageSize) {
                    this.cursorArray.reset(pagination.pageIndex);
                }

                this.pageIndex = pagination.pageIndex;
                this.pageSize = pagination.pageSize;

                this.getTransitionItemsData();
            });
    }

    public setupData(): void {
        this.cursorArray.reset(this.pageIndex);

        this.refreshData();
    }

    public refreshData(): void {
        this.setPageIndex();
    }

    private setPageIndex(pageIndex = 1): void {
        this.cursorArray.reset(pageIndex);
        this.paginatorService.setPageIndex(this.tableId, pageIndex);
    }

    /**
     * Get (next) publication items from API
     */
    public getTransitionItemsData(): void {
        this.transitionItemsDataSubscription?.unsubscribe();

        this.transitionItemsDataSubscription = this.publicationsApiService.getTransitionItemsData(
            this.publication._id,
            [ECustomWorkflowTransitionRecipeTaskName.DELETE_PUBLICATION_ITEMS],
            this.cursorArray.getCursor(this.pageIndex),
            this.pageSize,
            0,
            this.tableSortOptions)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (res: ARPagedResponseDataModel<TransitionItemDataModel>) => {
                    this.disableNextPage = !res.hasNext;
                    this.transitionItemsData = res.items;

                    if (res.hasNext) {
                        this.cursorArray.setCursor(this.pageIndex, this.transitionItemsData[this.transitionItemsData.length - 1]);
                    }
                },
                error: Toaster.handleApiError
            });
    }

    public onSortOptionChanged(event: ISortOptionEvent): void {
        this.tableSortOptions.updateWithSortOptionEvent(event);

        this.setPageIndex();
    }
}
