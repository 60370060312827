@if (formGroup) {
    <form [formGroup]="formGroup">
        <div class="component-form">
            <nuc-form-field label="Name">
                <nuc-input formControlName="name" placeholder="Enter name" required></nuc-input>
            </nuc-form-field>

            <nuc-form-field label="Component type">
                <nuc-dropdown
                    searchable="true"
                    formControlName="componentType"
                    placeholder="Choose a component type"
                    [nullOption]="false"
                    [items]="componentTypes"
                    (requestData)="onRequestComponentTypes($event)"
                    [total]="componentTypes.length">
                </nuc-dropdown>
            </nuc-form-field>
        </div>

        <div class="options-form">
            @if (hasColumnConfig() && !!formGroup.controls.options) {
                <form [formGroup]="formGroup.controls.options">
                    @for (optionControl of formGroup.controls.options.controls | keyvalue; track optionControl) {
                        <div>
                            <h2>Column config</h2>
                            <p>Set up the columns for your component</p>
                            <hr />
                            @for (columnGroupControl of optionControl.value.controls; let i = $index; track columnGroupControl) {
                                <form class="column-row" [formGroup]="columnGroupControl">
                                    <nuc-form-field label="Display name">
                                        <nuc-input formControlName="displayName"
                                                   placeholder="Enter display name"
                                                   required>
                                        </nuc-input>
                                    </nuc-form-field>

                                    <nuc-form-field label="Property">
                                        <nuc-dropdown formControlName="property"
                                                      [searchable]="true"
                                                      (requestData)="onSearchColumnProperty($event)"
                                                      [nullOption]="false"
                                                      [total]="columnConfigProperties.length"
                                                      [items]="columnConfigProperties"
                                                      placeholder="Select a property">
                                        </nuc-dropdown>
                                    </nuc-form-field>

                                    <div class="button">
                                        <div></div>
                                        <nuc-button-secondary
                                            icon="nucicon_trash_fill"
                                            nucTooltip="Remove column"
                                            (click)="removeColumn(optionControl.value, i)">
                                        </nuc-button-secondary>
                                    </div>
                                </form>
                            }
                        </div>
                        <nuc-button-bar>
                            <nuc-button-secondary
                                icon="nucicon_add"
                                nucTooltip="Add column"
                                (click)="addColumn(optionControl.value)"></nuc-button-secondary>
                        </nuc-button-bar>
                    }
                </form>
            }
            @if (hasAssetsFilterConfig() && !!formGroup.controls.options?.controls[EWorkflowComponentOptionName.ASSETS_FILTER]) {
                <div class="component-form">
                    <form [formGroup]="formGroup.controls.options">
                        <div [formGroupName]="EWorkflowComponentOptionName.ASSETS_FILTER">
                            <h2>Filter options</h2>
                            <p>Set up the filters for your component</p>
                            <hr />
                            <data-filter-template
                                [filtersForm]="formGroup.controls.options.controls[EWorkflowComponentOptionName.ASSETS_FILTER]"
                                [filters]="filters"/>
                        </div>
                    </form>
                </div>
            }
        </div>
    </form>
}
