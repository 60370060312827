import {EColumnDataType, EColumnType, ITableColumn} from '@relayter/rubber-duck';
import {RLDatePipe} from '../../pipes/rl-date.pipe';
import {AppConstants} from '../../app.constants';

export class AssetsTableConfig {
    public static readonly ASSETS_TABLE_DEFAULT_COLUMNS: ITableColumn[] = [
        {
            title: '',
            selectionColumnTitle: 'Thumbnail',
            key: 'files.thumbnail.url',
            type: EColumnType.THUMBNAIL,
            format: (value) => value ? value : AppConstants.ICONS.IMAGE_MAIN,
            clickable: true
        },
        {
            title: 'RIN',
            key: 'rin',
            sortProperty: 'rin'
        },
        {
            title: 'Name',
            key: 'name',
            sortProperty: 'name',
            sortDuplicates: true,
            dataType: EColumnDataType.STRING
        },
        {
            title: 'Type',
            key: 'type'
        },
        {
            title: 'Resolution',
            key: 'resolution'
        },
        {
            title: 'File size',
            key: 'size'
        },
        {
            title: 'Print size',
            key: 'printSizeName'
        },
        {
            title: 'File info',
            key: 'fileInfo'
        },
        {
            title: 'Date created',
            key: 'createdAt',
            sortProperty: 'createdAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        },
        {
            title: 'Date modified',
            key: 'updatedAt',
            sortProperty: 'updatedAt',
            sortDuplicates: true,
            dataType: EColumnDataType.DATE,
            format: (value) => RLDatePipe.format(value, RLDatePipe.dateFormats.TABLE_DETAILED)
        }
    ];

    public static readonly ASSETS_SELECTED_ICON_COLUMN: ITableColumn = {
        title: 'Selected',
        key: 'selected',
        type: EColumnType.ICON,
        iconClass: () => 'nucicon_check_round_fill',
        color: AppConstants.FIRST_BRAND_COLOR,
    };
}
