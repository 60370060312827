import {ApiService} from './api.service';
import {FontModel} from '../../models/api/font.model';
import {MonitoredJobsService} from './monitored-updates/monitored-jobs.service';
import {ApiConstants} from '../api.constant';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {JobModel} from '../../models/api/job.model';
import {ApiPostJobRequest} from '../requests/api-post-job.request';
import {tap} from 'rxjs/operators';

export enum EFontJobType {
    ADD_FONT_JOB = 'ADD_FONT_JOB',
    UPDATE_FONT_JOB = 'UPDATE_FONT_JOB'
}

export interface IUpdateFontJobData {
    fontId: string;
    s3Key: string;
    family?: string;
}

export interface ICreateFontJobData {
    family: string;
    s3Key: string;
}

export type IFontJobData =
    IUpdateFontJobData |
    ICreateFontJobData;

@Injectable({
    providedIn: 'root'
})
export default class FontsService extends ApiService<FontModel> {
    constructor(private monitoredJobsService: MonitoredJobsService) {
        super([ApiConstants.API_GROUP_FONTS], FontModel);
    }

    public postJob(jobType: EFontJobType, jobData: IFontJobData): Observable<JobModel> {
        const request = new ApiPostJobRequest(this.groups, JobModel, jobType, jobData);
        return this.apiRequestService.postJob(request)
            .pipe(
                tap((job: JobModel) => this.monitoredJobsService.addItemToMonitor(job))
            );
    }
}
